[data-ui-type="Store_Info_Offline"] {
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 24px 16px 20px;
    }

    h3 {
        font-size: $font-size-subtitle1;
        padding-bottom: 16px;
        color: $color-gray-02;
    }
    h4 {
        font-size: $font-size-subtitle2;
        font-weight: normal;
        padding-bottom: 10px;
        color: $color-gray-02;
    }
    .c-table-info {
        width: 100%;
        th,
        td {
            font-size: $font-size-body1;
            color: $color-gray-02;
            text-align: left;
            vertical-align: top;
            padding: 6px 0;
            word-break: break-word;
        }
        th {
            width: 100px;
            padding-left: 0;
            color: $color-gray-04;
            font-weight: normal;
        }
    }
    .c-offline-map {
        position: relative;
        margin: 10px 0 0;

        &__data {
            img {
                width: 100%;
            }
        }
    }
    .c-sns__info {
        margin: 20px -16px -20px;
        border-top: 1px solid $color-gray-11;
    }
}
