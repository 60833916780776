[data-ui-type="Store_Product_Sorting"] {
    &:first-child {
        .c-sorting:first-child {
            border-top: 0;
        }
    }
    [data-ui-type="Store_PageTitle"] + & {
        padding-top: 12px;
    }
    .c-sorting {
        height: 45px;
        border: 1px solid #f3f3f3;
        border-width: 1px 0;
        letter-spacing: $body-spacing;

        &__qty {
            padding: 0 16px;

            &:after {
                display: none;
            }
            strong {
                color: #333;
            }
        }
        &__order {
            border-color: #f3f3f3;

            &__button {
                padding: 0 46px 0 38px;
                border: 0;
                background: none;
                outline: none;

                &:before {
                    left: 9px;
                }
            }
        }
        &__view {
            border-color: #f3f3f3;
        }
        .c-tab__list {
            float: right;
            width: auto;
            margin-bottom: 16px;
            padding: 4px 16px;
        }
    }
    .c-tab__list {
        display: block;
        padding: 6px 16px;
        text-align: right;

        &:first-child {
            margin-top: -12px;
        }
        button {
            display: inline-flex;
            border: 0;
            background: none;
            font-size: 13px;
            color: #666;
            line-height: 36px;
            outline: none;
            align-items: center;

            & + button {
                margin-left: 22px;
            }
            &:before {
                display: inline-block;
                width: 6px;
                height: 11px;
                margin: -3px 9px 0 0;
                border: solid #ccc;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                content: "";
            }
            &[aria-checked="true"] {
                color: #111;

                &:before {
                    border-color: $color-11st-red;
                }
            }
        }
    }
    .c-filter__range {
        display: flex;
        padding: 12px 16px;
        border-bottom: 1px solid #f3f3f3;
        background: #fafafa;
        font-size: 13px;

        &-inpbox {
            flex: 1;

            input {
                width: 100%;
                height: 32px;
                padding: 0 12px;
                border: 1px solid #ddd;
                font-size: 13px;
                color: #111;
                outline: none;

                &::placeholder {
                    color: #999;
                }
            }
        }
        &-scope {
            display: inline-block;
            width: 22px;
            color: #999;
            text-align: center;
            line-height: 32px;
        }
        &-btn {
            width: 50px;
            margin-left: 8px;
            border: 1px solid #ddd;
            background: #fff;
            font-size: 13px;
            color: $color-gray-03;
            line-height: 30px;
            outline: none;
        }
    }
    .c-filter-box {
        display: flex;
        overflow: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        white-space: nowrap;
        padding: 16px;
        &__delivery {
            display: flex;
            position: relative;
            margin-right: 10px;
            padding: 0 10px 0 0;
            &:after {
                position: absolute;
                top: 2px;
                right: 0;
                bottom: 2px;
                border-right: 1px solid $color-gray-11;
                content: '';
            }
        }
        &__promotion {
            display: flex;
        }
    }
    .c-chip-checkbox {
        &:not(:first-child) {
            margin-left: 6px;
        }
        .c-chip {
            margin: 0;
            padding: 0 8px;
            border-radius: 4px;
            border-color: $color-gray-10;
            font-size: 13px;
            color: $color-gray-02;
        }
        .c-checkbox {
            &:checked {
                & + {
                    .c-chip {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-right: 23px;
                        font-weight: normal;
                        background-color: $color-gray-14;
                        &:before, &:after {
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: 13px;
                            left: auto;
                            width: 1px;
                            height: 12px;
                            margin: -6px 0 0;
                            border: none;
                            border-radius: .5px;
                            transform: rotate(45deg);
                            opacity: .8;
                            content: "";
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

// 아마존 스킨
.skin_14 {
    [data-ui-type="Store_Product_Sorting"] {
        margin-bottom: 24px;
    }
}
