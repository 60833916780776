[data-ui-type="Store_Product_Card"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 0;
        background-color: $color-gray-14;

        &:after {
            border-color: #d1d1d6;
        }
        & + .l-grid__col {
            margin-top: 10px;
        }
        [data-ui-type="Store_PageTitle"] + &:first-child {
            padding-top: 8px;
        }
    }

    //이전 산출물 제거 후 삭제예정
    .c-card__free {
        font-weight: normal;
        color: #666;
        line-height: 40px;
    }
    // //이전 산출물 제거 후 삭제예정

    .c-card__qty {
        bottom: 16px;
        font-size: 13px;
        color: #a9a9a9;
        em {
            color: $color-11st-blue;
        }
        &--urgent {
            color: $color-11st-red;
            animation: 3s linear 0s urgent infinite;

            & + .c-card__qty--urgent {
                animation-delay: -1.5s;
            }
        }
    }
    .c-addition-link--store {
        display: none;
    }
    .c-card__counsel {
        height: 37px;
        line-height: 37px;
        border-top: 1px solid #f4f4f4;
        padding: 0 16px;
        font-size: 13px;
    }
    .c-card__name + .c-card__counsel {
        height: auto;
        border: 0 none;
        padding: 5px 0 10px;
        line-height: 1;
    }
    .c-flag-box {
        margin: 0 0 2px;
    }
    .c-card__name {
        em {
            margin-right: 4px;
            color: $color-11st-blue;
        }
    }
    .c-flag__item--ooah,.c-flag__item--freshtable {
        margin: 3px 4px 7px 0;
    }
}

@keyframes urgent {
    0%,
    25%,
    75%,
    100% {
        opacity: 0;
    }
    35%,
    65% {
        opacity: 1;
    }
}
