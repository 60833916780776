[data-ui-type="Store_GNB"] {
    position: relative;
    z-index: 31;

    // MPSR-60966 삭제예정
    .header-relative & {
        .c-nav {
            margin-top: -6px;
        }
    }
    .header-fixed & {
        .b-border {
            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
        .b-fixed {
            position: fixed;
            left: initial;
            max-width: 1280px;

            .c-gnb {
                &__menu {
                    p {
                        top: 0;
                        opacity: 0;
                    }
                    .c-gnb__service-title {
                        margin-top: 18px;
                        margin-bottom: 0;
                    }
                }
                &__menu--flow {
                    display: table;
                    width: 100%;
                    height: 56px;
                    table-layout: fixed;
                    box-sizing: border-box;

                    .c-gnb__service-title {
                        display: table-cell;
                        width: 100%;
                        padding: 0;
                        vertical-align: middle;

                        &:first-child {
                            margin-top: 0;
                        }
                        a {
                            display: inline-block;
                            overflow: hidden;
                            max-width: 90%;
                            vertical-align: middle;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    // //MPSR-60966 삭제예정
    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .b-fixed {
        @include transition(0.3s);
        position: relative;
        background-color: transparent;
        .c-gnb {
            background-color: transparent;
            border-bottom: none;
            color: $color-gray-14;
            height: auto;
            min-height: 56px;

            &__menu {
                overflow: initial;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: auto;
                min-height: 59px;
                padding: 0 92px 0 16px;
                box-sizing: border-box;

                p {
                    @include transition(0.3s); // 삭제예정
                    position: absolute;
                    left: 16px;
                    top: 8px;
                    font-size: 0;
                    white-space: nowrap;
                    opacity: 1;

                    .c-tag {
                        display: inline-block;
                        padding: 0px 4px 2px;
                        margin: 0 4px 0 0;
                        height: 13px;
                        line-height: 15px;
                        font-size: 12px;
                        font-weight: normal;
                        color: rgba(255, 255, 255, 0.9);
                        background-color: rgba(255, 255, 255, 0.1);
                        border-color: transparent;
                        border-radius: 2px;
                        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                        box-sizing: content-box;
                    }

                    & ~ .c-gnb__service-title {
                        margin-top: 25px;
                    }
                }
            }
            &__menu--flow {
                .c-gnb__service-title {
                    font-size: 18px;
                    line-height: 1.2;
                }
                p ~ .c-gnb__service-title {
                    margin-top: 30px;
                }
            }
            &__service-title {
                @include transition(0.3s); // 삭제예정
                display: inline-block;
                overflow: initial;
                height: auto;
                margin: 0;
                font-size: $font-size-headline3;
                color: $color-gray-14;
                word-break: break-word;
                a {
                    color: $color-gray-14;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                    line-height: 1.2;
                }
            }
            &__search-icon {
                @include sprite-retina($sp_store_new_gnb_search);
            }
            &__cart {
                @include sprite-retina($sp_store_new_gnb_cart);
                .c-badge {
                    background-color: $color-gray-14;
                    line-height: 17px;
                    text-indent: 1px;
                }
            }
            &__back {
                @include sprite-retina($sp_store_new_gnb_back);

                position: absolute;
                top: 10px;
                left: 8px;
                z-index: 10;
                border: 0;
                background-color: transparent;

                & ~ .c-gnb__menu {
                    margin-left: 36px;
                }
            }
            .c_info {
                display: inline-block;
                padding-left: 2px;
                span {
                    @include sprite-retina($sp_store_new_info_b);
                    display: inherit;
                    margin: -2px 0 2px;
                    text-indent: -99999px;
                    vertical-align: middle;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                }
            }
            &--sub {
                .c-gnb__menu {
                    display: table;
                    width: 100%;
                    height: 56px;
                    padding: 0 92px 0 16px;
                    box-sizing: border-box;
                }

                .c-gnb__service-title {
                    display: table-cell;
                    padding: 0;
                    vertical-align: middle;
                    line-height: 2px;

                    &:first-child {
                        padding: 10px 0;
                    }
                }

                .c-gnb__back ~ .c-gnb__menu {
                    margin-left: 0;
                    padding-left: 52px;
                }
            }
        }
    }
    .c-nav {
        @include transition(0.3s);
        background-color: transparent;
        border-bottom: none;
        white-space: nowrap;

        &__item {
            display: inline-block;

            a {
                color: rgba(255, 255, 255, 0.7);
                font-size: 17px;
                line-height: 40px;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

                &:after {
                    left: 10px;
                    right: 10px;
                    width: auto;
                }
            }
            &:first-child {
                padding-left: 8px;

                &.on {
                    a:after {
                        left: 4px;
                        right: 4px;
                    }
                }
            }
            &:last-child {
                padding-right: 8px;
            }
            &.on {
                a {
                    padding: 2px 10px;
                    color: rgba(255, 255, 255, 1);

                    &:after {
                        background-color: $color-gray-14;
                    }
                }
            }
            &.c-nav__item--total {
                a {
                    position: relative;
                    padding-right: 19px;

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                    }
                    &:before {
                        border: 1px solid rgba(255, 255, 255, 0.7);
                        border-radius: 12px;
                        width: 16px;
                        height: 16px;
                        top: 12px;
                        right: 0;
                    }
                    &:after {
                        border: 1px solid rgba(255, 255, 255, 0.7);
                        border-width: 0 1px 1px 0;
                        transform: rotate(315deg);
                        width: 5px;
                        height: 5px;
                        top: 18px;
                        left: initial;
                        right: 7px;
                    }
                }
            }
        }
        &--spread {
            .c-nav__item {
                a {
                    font-weight: bold;
                }
            }
        }
    }
    .is-fixed-header & {
        position: fixed;
        top: 0;
        width: 100%;
        max-width: 1280px;
    }
}
// 스킨 변경시 예외 케이스
.skin_13 {
    [data-ui-type="Store_GNB"] {
        .b-fixed {
            .c-gnb {
                &__menu {
                    p {
                        .c-tag {
                            background-color: rgba(17, 17, 17, 0.1);
                            color: $color-gray-02;
                            text-shadow: none;
                        }
                    }
                }
                &__service-title {
                    color: $color-11st-red;
                    a {
                        color: $color-11st-red;
                        text-shadow: none;
                    }
                }
                &__search-icon {
                    @include sprite-retina($sp_store_new_gnb_search_b);
                }
                &__cart {
                    @include sprite-retina($sp_store_new_gnb_cart_b);
                    .c-badge {
                        color: $color-gray-14;
                        background-color: $color-11st-red;
                    }
                }
                .c_info {
                    span {
                        @include sprite-retina($sp_store_new_info_b2);
                    }
                }
            }
        }
        .c-nav {
            &__wrap {
                .c-nav__item {
                    a {
                        color: $color-gray-02;
                        text-shadow: none;
                    }
                    &.on {
                        a {
                            color: $color-11st-red;

                            &:after {
                                background-color: $color-11st-red;
                            }
                        }
                    }
                    &.c-nav__item--total {
                        a {
                            &:before,
                            &:after {
                                border-color: $color-gray-02;
                            }
                        }
                    }
                }
            }
        }
    }
}

// 아마존용 스킨
.skin_14 {
    [data-ui-type="Store_GNB"] {
        .b-fixed {
            .c-gnb {
                &__service-title {
                    &:first-child {
                        padding-top: 4px;
                    }
                    a {
                        @include logo-svg($logo-amazon-black, 76px, 23px);
                        display: block;
                        font-size: 0;
                    }
                }
                &__search-icon {
                    @include sprite-retina($sp_store_new_gnb_search_b);
                }
                &__cart {
                    @include sprite-retina($sp_store_new_gnb_cart_b);
                    .c-badge {
                        color: $color-gray-14;
                        background-color: $color-11st-red;
                    }
                }
                .c_info {
                    span {
                        @include sprite-retina($sp_store_new_info_b2);
                    }
                }
            }
        }
        .c-nav {
            &__wrap {
                .c-nav__item {
                    a {
                        color: $color-gray-02;
                        text-shadow: none;
                    }
                    &.on {
                        a {
                            color: $color-11st-red;

                            &:after {
                                background-color: $color-11st-red;
                            }
                        }
                    }
                    &.c-nav__item--total {
                        a {
                            &:before,
                            &:after {
                                border-color: $color-gray-02;
                            }
                        }
                    }
                }
            }
        }
    }
}
