[data-ui-type="Store_ProductScroll_Basic"] {
    .s-list {
        padding: 6px 10px 14px;

        li {
            padding: 6px;
        }
    }
    .c-card__gallery {
        width: 142px;

        .c-card__thumb {
            width: 142px;
            height: 142px;
        }
        .c-card__info {
            min-height: initial !important; //서비스 반영 후 important 삭제 예정
            padding: 8px 0 0;

            .c-card__name {
                height: 38px;
                margin-bottom: 8px;
                font-size: 14px;
            }
            .c-card__rate {
                vertical-align: baseline;

                strong {
                    font-weight: normal;
                }
            }
            .c-card__price {
                height: auto;
                vertical-align: baseline;
            }
        }
    }
}
