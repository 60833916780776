/* cts */
#cts {
    position: relative;
    margin: 0 0 10px;
}

body.grayType {
    background: $color-gray-11;
}

.lad {
    width: 100%;
    z-index: 99;

    div {
        width: 100%;
        height: 48px;
        text-align: center;
    }

    a {
        display: block;
        width: 100%;
        height: 48px;
    }

    img {
        width: 320px;
        height: 48px;
        vertical-align: top;
    }
}
