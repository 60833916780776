@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("//c.m.011st.com/MW/css/fonts/outfit-v5-latin-500.woff2") format("woff2"), url("//c.m.011st.com/MW/css/fonts/outfit-v5-latin-500.woff") format("woff");
}
@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("//c.m.011st.com/MW/css/fonts/outfit-v5-latin-600.woff2") format("woff2"), url("//c.m.011st.com/MW/css/fonts/outfit-v5-latin-600.woff") format("woff");
}
