@import "../../common/sprites/sp_eui";

.c-toast {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 999;
    padding: 16px 16px 14px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: $font-size-body1;
    color: $color-gray-14;
    letter-spacing: 0;
    box-sizing: border-box;

    &.modal-bottom {
        top: initial;
        right: 16px;
        left: 16px;
        transform: translate(0, -16px);
    }

    &.slideInBottom {
        animation-name: slideInBottomToast;
    }

    @keyframes slideInBottomToast {
        from {
            opacity: 0;
            transform: translate(0, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, -16px);
        }
    }

    &.slideOutBottom {
        animation-name: slideOutBottomToast;
    }

    @keyframes slideOutBottomToast {
        from {
            opacity: 1;
            transform: translate(0, -16px);
        }

        to {
            opacity: 0;
            transform: translate(0, 0);
        }
    }

    &__title {
        position: relative;
        padding-left: 26px;
        font-size: $font-size-body1;
        font-weight: normal;

        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 4px;
            width: 6px;
            height: 10px;
            border: 2px solid $color-gray-14;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg);
            content: "";
        }

        & + .c-toast__text {
            padding: 2px 0 0 26px;
        }
    }

    &__text {
        position: relative;
        font-size: $font-size-body1;
        font-weight: normal;

        strong {
            font-weight: normal;
            color: $color-11st-red;
        }

        &--icon {
            padding-left: 26px;

            &:before {
                @include sprite-retina($sp_eui_error);
                position: absolute;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                content: "";
            }
        }
    }

    &__close {
        overflow: hidden;
        position: absolute;
        top: 8px;
        right: 10px;
        width: 36px;
        height: 36px;
        border: none;
        background: none;
        color: transparent;
        line-height: 200px;

        &:before,
        &:after {
            position: absolute;
            left: auto;
            right: 50%;
            top: 50%;
            width: 1px;
            height: 16px;
            margin: -10px 0 0 -1px;
            background: $color-gray-14;
            transform: rotate(45deg);
            content: "";
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

// new (2025.01)
.c-toast-box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: var(--toastBottomPosition, 16px);
    z-index: 101; // 라이브11 전시 > 지금 라이브중 알럿과 위로 올라와야 하기에 101로 변경
    padding: 0 16px;
    text-align: center;
    transform: translateY(120%);
    opacity: 0;

    // status
    &--in {
        animation: toastPopIn 0.4s;
        animation-fill-mode: forwards;
    }
    &--out {
        animation: toastPopOut 0.4s;
        animation-fill-mode: forwards;
    }

    * {
        font-family: $font-family3;
    }

    &__inner {
        display: inline-flex;
        align-items: center;
        min-height: 48px;
        border-radius: 100px;
        backdrop-filter: blur(16px);
        vertical-align: top;
        box-sizing: border-box;
    }
    &__icon {
        flex-shrink: 0;
        margin-left: 20px;
        width: 40px;
        height: 40px;

        img,
        svg {
            width: 100%;
            height: 100%;
        }
    }
    &__text {
        overflow: hidden;
        max-height: 34px;
        margin: 11px 0;
        color: $color-gray-14;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        text-align: left;
        &:first-child {
            padding-left: 28px;
        }
        &:last-child {
            padding-right: 28px;
        }
    }
    &__action {
        flex-shrink: 0;
    }
    &__link {
        display: flex;
        align-items: center;
        min-height: 48px;
        margin-left: 16px;
        padding-right: 28px;
        font-size: 14px;
        line-height: 17px;
        &:after {
            @include ico-arrow-right(6px, currentColor);
            content: "";
            display: inline-block;
            margin: 0 0 2px 2px;
        }
    }
    &__close {
        min-height: 48px;
        padding-left: 16px;
        padding-right: 28px;
        border: 0;
        background: none;
    }

    // theme
    &--light {
        .c-toast-box {
            &__inner {
                background: $color-gray-14;
                box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06), 0px 1px 6px 0px rgba(0, 0, 0, 0.04);
            }
            &__text {
                color: $color-gray-02;
            }
            &__link {
                color: $color-11st-blue;
            }
            &__close {
                path {
                    fill: $color-gray-02;
                }
            }
            &__icon-error {
                circle {
                    fill: $color-gray-03;
                    stroke: none;
                }
            }
        }
    }
    &--dark {
        .c-toast-box {
            &__inner {
                background: rgba($color-gray-01, 0.7);
            }
            &__text {
                color: $color-gray-14;
            }
            &__link {
                color: #6ebbfa;
            }
            &__close {
                path {
                    fill: $color-gray-10;
                }
            }
            &__icon-error {
                circle {
                    fill: none;
                    stroke: $color-gray-04;
                }
            }
        }
    }
    @keyframes toastPopIn {
        0% {
            transform: translateY(120%);
            opacity: 0;
        }
        50% {
            transform: translateY(-20%);
            opacity: 1;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
    @keyframes toastPopOut {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(120%);
            opacity: 0;
        }
    }
}
