@import "../../common/sprites/sp_eui";

.c-flag__item {
    float: left;
    margin: 0 4px 4px 0;
    @include icon-flag("c-flag__item--");
}

.c-flag {
    display: block;
    overflow: hidden;
    height: 22px;

    &:after {
        display: block;
        clear: both;
        content: "";
    }
    dt {
        position: absolute;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        font-size: 6px;
        line-height: 1;
        white-space: nowrap;
    }
}

.c-flag__item--11festa {
    border-color: rgba($color: $color-11st-red, $alpha: 0.9);
    background-color: rgba($color: $color-11st-red, $alpha: 0.9);
    color: $color-gray-14;
}

.c-flag__item--gradient {
    border: 0;
    background: linear-gradient(to right, #1367ff 0%, #00dbdf 100%);
    color: $color-gray-14;
    line-height: 16px;
}

.c-flag__item--gradient-red {
    border: 0;
    background: linear-gradient(to right, #ff4466 0%, $color-11st-gradient-pink 100%);
    color: $color-gray-14;
    line-height: 16px;
}

.c-flag__item--choice {
    border-color: #232f3e;
    background: #232f3e;
    color: $color-gray-14;
}

.c-flag__item--11festival {
    border-color: rgba($color: $color-11st-red, $alpha: 0.4);
    background-color: rgb(255, 247, 248);
    color: $color-11st-red;
}

.c-flag__item--image {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;

    img {
        display: block;
        height: 100%;
    }
}

// [M] 로고 공통 스타일 믹스인(커스텀 필요시 따로 선언)
@mixin logo-common-styles {
    padding: 0;
    border: none;
    color: transparent;
}

// [M] logo-data: 클래스 이름(c-flag__item--), 이미지 파일명, 아이콘 사이즈
$logo-data: (
    "ooah": (
        "logo_ooah",
        36,
    ),
    "freshtable": (
        "logo_freshtable",
        52,
    ),
    "freshtable-filled": (
        "logo_freshtable_filled",
        58,
    ),
    "ootd": (
        "logo_ootd",
        50,
    ),
    "shooting-delivery": (
        "logo_round_shooting_delivery",
        52,
    ),
    "shooting-freeze": (
        "logo_round_shooting_freeze",
        54,
    ),
    "shooting-fresh": (
        "logo_round_shooting_fresh",
        54,
    ),
    "shooting-install": (
        "logo_round_shooting_install",
        53,
    ),
    "shooting-plus": (
        "logo_round_shooting_plus",
        72,
    ),
    "shooting-seller": (
        "logo_round_shooting_seller",
        56,
    ),
    "ssg-emart": (
        "logo_round_ssg_emart",
        65,
    ),
    "ssg-dawn": (
        "logo_round_ssg_dawn",
        66,
    ),
    "mart-plus": (
        "logo_martplus",
        60,
    ),
);

// 로고 반복문
@each $name, $sprite in $logo-data {
    .c-flag__item--#{$name} {
        @include logo-common-styles;
        @include get-sprite-common(nth($sprite, 1), nth($sprite, 2));
    }
}

.c-flag-box {
    overflow: hidden;
    height: 22px;

    dt {
        @include sr-only;
    }
    .c-flag {
        overflow: initial;
        height: auto;
    }
}
