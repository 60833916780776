[data-ui-type="Store_Product_ImgOverPrice"] {
    padding: 6px 10px 10px;
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 6px;
    }
    .c-card__counsel {
        position: relative;
        font-size: 14px;
        font-weight: bold;
        padding: 0;

        em {
            color: #fff;
        }
    }
    .c-card__onlyprice {
        border: 0;

        .c-card__info {
            background: rgba(0, 0, 0, 0.12);

            &:before {
                display: none;
            }
            .c-card__price {
                font-size: 12px;

                strong {
                    font-size: 14px;
                }
            }
        }
    }
}