[data-ui-type="Store_BillBoard"] {
    .w-swiper {
        [class*="swiper-button"] {
            visibility: hidden;
        }
    }
    .c-pagination {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;

        &__indicator {
            span {
                margin-top: -2px;
                margin-bottom: 2px;
                vertical-align: middle;

                & + span {
                    margin-left: 6px;
                }
                &.on {
                    background-color: $color-gray-02;
                }
            }
        }
    }
}
