[data-ui-type="Store_Contents_Home_Intro"] {
    display: block;
    background-color: $color-gray-13;
    padding: 20px 0 16px;

    .c-storeinfo {
        &--badge {
            display: block;
            padding: 0 16px 12px;
            span {
                display: inline-block;
                padding: 0 4px 4px 0;
                font-size: $font-size-caption1;
                color: $color-gray-02;
                line-height: 16px;
                img {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin: -2px 1px 2px 0;
                    vertical-align: middle;
                }
            }
        }
        &--button {
            position: relative;
            display: block;
            padding: 0 68px 20px 16px;

            &:last-child {
                padding-bottom: 2px;
            }

            button {
                padding: 0;
                margin: 0;
                border: 0;
                outline: none;
                width: 100%;
                height: 42px;
                line-height: 44px;
                background-color: $color-gray-14;
                span {
                    font-size: $font-size-subtitle2;
                    color: $color-gray-02;
                    padding-left: 26px;
                }
            }

            ul {
                width: 100%;
                display: table;
                li {
                    display: table-cell;
                    width: 50%;
                    border: 1px solid $color-gray-10;
                    &:nth-of-type(2) {
                        border-left: 0;
                    }
                }
            }

            .c-button {
                &--favorite {
                    span {
                        position: relative;
                        &:before {
                            @include zzim-store(off);
                            position: absolute;
                            left: -3px;
                            top: -4px;
                        }
                    }
                    &.on {
                        span {
                            &:before {
                                @include zzim-store(on);
                            }
                        }
                    }
                }
                &--add {
                    span {
                        position: relative;
                        &:before {
                            @include add-11talk(off);
                            position: absolute;
                            left: -2px;
                            top: -4px;
                        }
                    }
                    &.on {
                        span {
                            &:before {
                                @include add-11talk(on);
                            }
                        }
                    }
                }
                &--share {
                    position: absolute;
                    right: 16px;
                    top: 0;
                    width: 44px;
                    height: 44px;
                    border: 1px solid $color-gray-10;
                    border-radius: 0;
                }
            }

            & + .c-storeinfo--rating {
                padding-top: 0;
            }
        }
        &--segment {
            font-size: $font-size-body2;
            color: $color-gray-02;
            padding: 0 16px 6px;
            li {
                position: relative;
                display: inline-block;
                padding-right: 12px;
                &:after {
                    content: ".";
                    display: block;
                    font-weight: bold;
                    color: $color-gray-10;
                    position: absolute;
                    top: -4px;
                    right: 3px;
                }
                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
            .c-info {
                display: inline-block;
                position: relative;
                width: 14px;
                height: 14px;
                margin: -2px 0 2px;
                border: 0;
                background: 0;
                vertical-align: middle;
                text-indent: -99999px;
                outline: none;
                &:before {
                    @include sprite-retina($sp_store_new_info_s);
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
        &--rating {
            display: table;
            width: 100%;
            padding-top: 8px;
            li {
                position: relative;
                display: inline-block;
                font-size: $font-size-body2;
                width: 25%;
                height: 40px;
                vertical-align: top;
                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 16px;
                    background-color: #eee;
                    position: absolute;
                    top: 10px;
                    left: 0;
                }

                dl {
                    display: block;
                    width: 100%;
                    text-align: center;
                    dt {
                        padding-bottom: 4px;
                    }
                    dd {
                        height: 20px;
                        span {
                            display: inline-block;
                            border-width: 0;
                            border-style: solid;
                            border-radius: 12px;
                        }
                    }
                }

                &:first-child {
                    dl {
                        dd {
                            span {
                                height: 19px;
                                border-width: 1px;
                                padding: 0 4px;
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                    &:before {
                        display: none;
                    }
                }
            }
            &.c-storeinfo--rating-col3 {
                padding-top: 0;
                li {
                    width: initial;
                    height: 16px;
                    display: inline-block;
                    padding-left: 14px;
                    vertical-align: baseline;
                    dl {
                        dt,
                        dd {
                            display: inline;
                        }
                        dt {
                            margin-right: 3px;
                        }
                    }
                    &:first-child {
                        padding-left: 16px;
                    }
                    &:before {
                        background-color: #ddd;
                        width: 2px;
                        height: 2px;
                        top: 7px;
                        left: 6px;
                    }
                }
            }
        }
    }
    [data-ui-type="Store_Contents_Home_Swiper"] + & {
        padding-top: 18px;
        background: $color-gray-14;
    }
}
