[data-ui-type="Store_PageTitle"] {
    .c-headline {
        &__title {
            display: flex;
            overflow: hidden;
            padding: 20px 16px 0;
            font-size: 20px;
            font-weight: bold;
            justify-content: center;

            .ellipsis {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            &-page {
                display: flex;
                overflow: hidden;
                position: relative;
                padding: 17px 16px 0;
                font-size: 14px;
                font-weight: normal;
                color: #111;
                line-height: 40px;
                white-space: nowrap;

                strong {
                    display: inline-block;
                    font-size: 18px;
                    font-weight: bold;
                    vertical-align: middle;

                    & + span {
                        margin-left: 3px;
                        font-size: 18px;
                        vertical-align: middle;
                    }
                    &.ellipsis {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        &__subtext {
            position: relative;
            margin-top: -6px;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            color: #111;

            span {
                display: inline-block;
                position: relative;
                margin-bottom: 8px;

                &:before {
                    position: absolute;
                    top: 5px;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    opacity: 0.1;
                    content: '';
                }
            }
        }
        &__link {
            text-align: center;

            &:after {
                top: 35px;
                margin-top: 0;
            }
        }
        &--border {
            border-bottom: 1px solid #eee;

            .c-headline__title-page {
                height: 68px;
            }
        }
    }
}