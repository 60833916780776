.c-headline {
    background: $color-gray-14;
    display: block;
    position: relative;
    padding: 0;
    width: auto;
    text-align: left;

    &__text {
        display: block;
        position: relative;

        .c-ad__btn {
            position: absolute;
            top: 50%;
            right: 8px;
            margin-top: -9px;
        }

        .c-headline__close {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 55px;
            height: 55px;
            color: transparent;
            font-size: 1px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 3px;
                height: 48px;
                //margin-top: -12px;
                background-color: $color-gray-03;
                transform: translateY(-50%) scale(0.5) rotate(45deg);
            }

            &:after {
                transform: translateY(-50%) scale(0.5) rotate(-45deg);
            }
        }
    }

    &__link {
        display: block;
        position: relative;

        &:after {
            @include rotate(45deg);
            @include size(9px);
            content: "";
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -4px;
            border: solid $color-gray-06;
            border-width: 1px 1px 0 0;
        }

        .c-headline__txtlink {
            color: $color-gray-07;
            line-height: 0;
            position: absolute;
            font-size: 13px;
            top: 51%;
            right: 30px;
        }

        .c-headline__iconlink {
            display: block;
            position: absolute;
            top: 18px;
            right: 17px;
            max-width: 64px;
            max-height: 24px;
            padding-right: 10px;
            img {
                display: block;
                width: 100%;
                max-height: 24px;
            }
        }
    }

    &__title {
        color: $color-gray-02;
        display: block;
        position: relative;
        height: 60px;
        padding: 11px 16px;
        white-space: nowrap;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
        overflow: hidden;
        box-sizing: border-box;

        strong + span {
            display: inline-block;
            position: relative;
            margin-left: 10px;
            padding: 0 5px 0 12px;
            font-size: 15px;
            font-weight: normal;
            color: $color-gray-04;
            line-height: 1;

            &:before {
                position: absolute;
                top: 1px;
                left: 0;
                width: 1px;
                height: 12px;
                background-color: $color-gray-11;
                content: "";
            }
        }

        .c-headline__point {
            display: inline-block;
            margin: 1px 0 -1px;
            font-size: 16px;
            font-weight: normal;
            line-height: 1;
            vertical-align: middle;
        }
    }

    &__subtext {
        display: block;
        overflow: hidden;
        margin-top: -20px;
        padding: 4px 16px 14px;
        font-size: 12px;
        color: $color-gray-04;
        line-height: 18px;
        white-space: nowrap;
    }

    &__point {
        color: $color-11st-red;
        letter-spacing: $body-spacing;
    }
    &__button {
        padding: 0 24px 0 12px;
        display: block;
        height: 32px;
        line-height: 32px;
        position: absolute;
        top: 14px;
        right: 16px;
        border: 1px solid $color-gray-10;
        border-radius: 16px;
        font-size: 13px;
        color: $color-gray-03;
        &::after {
            position: absolute;
            right: 12px;
            top: 12px;
            display: block;
            @include ico-arrow-right(6px, $color-gray-10, 1px);
            @include rotate(45deg);
        }
    }
    &__time {
        margin: 11px 16px;
        border: 0;
        background: none;
        font-size: 13px;
        color: $color-gray-04;
        line-height: 40px;

        &:after {
            @include sprite-retina($sp_eui_arrow_refresh3);
            display: inline-block;
            margin: -2px 0 1px 6px;
            vertical-align: middle;
            content: "";
        }
    }

    // headline Properties
    &--center {
        .c-headline__title {
            text-align: center;
        }
        .c-headline__subtext {
            text-align: center;
        }
    }

    &--normal {
        .c-headline__title,
        .c-headline__subtext {
            font-weight: normal;
        }
    }

    &--high {
        .c-headline__title {
            height: 72px;
            padding: 16px;
        }
        .c-headline__time {
            padding: 16px;
        }
    }

    &--low {
        .c-headline__title {
            height: 56px;
            padding: 8px 16px;
        }
        .c-headline__time {
            padding: 8px 16px;
        }
    }
}
