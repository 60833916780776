[data-ui-type="Store_Info_Review"] {
    letter-spacing: $body-spacing;

    h3 {
        padding: 24px 16px 0;
        margin-bottom: 10px;
        font-size: $font-size-subtitle1;
        font-weight: bold;
        color: $color-gray-02;
        button {
            @include sprite-retina($sp_store_new_info_s);
            display: inline-block;
            outline: none;
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            text-indent: -99999px;
            vertical-align: middle;
            margin-top: -2px;
        }
    }

    .c-store-review__point {
        display: table;
        width: 100%;
        li {
            display: table-cell;
            width: 50%;
            text-align: center;
            vertical-align: top;
            .c-graph {
                position: relative;
                display: table;
                width: 160px;
                font-size: $font-size-caption1;
                &:after {
                    content: "";
                    display: block;
                    width: 140px;
                    height: 1px;
                    background-color: #eee;
                    position: absolute;
                    left: 9px;
                    bottom: 0;
                }
                &-wrap {
                    width: 160px;
                    height: 100px;
                    padding: 20px 0 0 0;
                }
                li {
                    position: relative;
                    display: table-cell;
                    width: 32px;
                    height: 50px;
                    text-align: center;
                    .c-graph__bar {
                        position: relative;
                        display: block;
                        margin: 0 auto;
                        width: 12px;
                        height: 100%;
                        .c-graph__label {
                            position: absolute;
                            bottom: -20px;
                            left: -2px;
                            white-space: nowrap;
                        }
                        .c-graph__qty {
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            padding-bottom: 3px;
                            color: #111;
                            text-align: center;
                            white-space: nowrap;
                            transform: translateX(-50%);
                        }
                        .c-graph__data {
                            display: block;
                            background-color: #eee;
                            width: 12px;
                            position: absolute;
                            bottom: 0;
                            height: 0;
                            text-indent: -999999px;
                        }
                    }
                }
            }
            dl {
                padding: 20px 0 25px 0;
                color: $color-gray-02;
                dd {
                    font-size: 40px;
                }
                dt {
                    font-size: $font-size-subtitle2;
                }
            }
        }
    }

    .c-store-review__stat {
        border-top: 1px solid #f3f3f3;
        background-color: #fafafa;
        height: 80px;
        padding-top: 20px;
        ul {
            display: table;
            width: 100%;
            li {
                display: table-cell;
                width: 25%;
                text-align: center;
                vertical-align: top;

                dl {
                    dt {
                        font-size: $font-size-body2;
                        color: $color-gray-04;
                    }
                    dd {
                        padding-bottom: 5px;
                        font-size: $font-size-subtitle2;
                        font-weight: bold;
                        color: $color-gray-02;
                    }
                }
                .c-gauge-sales {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 8px;
                    text-indent: -99999px;
                    width: 32px;
                    height: 16px;
                    background-image: url("/MW/img/store/gauge.png");
                    background-size: 66px 56px;
                    background-position: 0 0;
                    &--1 {
                        background-position: 32px 0px;
                    }
                    &--2 {
                        background-position: 0 36px;
                    }
                    &--3 {
                        background-position: 32px 36px;
                    }
                    &--4 {
                        background-position: 0 72px;
                    }
                    &--5 {
                        background-position: 32px 72px;
                    }
                }
                span {
                    display: block;
                    font-size: $font-size-body2;
                    color: $color-gray-04;
                }
            }
        }
    }
}
