@import "../../common/sprites/sp_eui";
@mixin add-11talk($switch:on) {
    content: '';
    display: block;
    @if $switch=="on" {
        @include sprite-retina($sp_eui_add_talk_on);
    }
    @else if $switch=="off" {
        @include sprite-retina($sp_eui_add_talk);
    }
}
