[data-ui-type="Store_Category_Sub"] {
    .c-category__sub {
        position: relative;

        .c-tag {
            margin: 0;
            padding: 0 12px;
            border-color: transparent;
            background: #fafafa;
            color: $color-gray-02;
            line-height: 30px;
            outline: none;

            &.active {
                font-weight: normal;
                color: #fff;
            }
        }
        .total {
            .c-tag {
                &:after {
                    @include ico-arrow-right(8px, #999, 1px);
                    position: static; // mixin 배포 후 삭제예정
                    display: inline-block;
                    margin: -1px 0 1px 2px;
                    vertical-align: middle;
                    content: "";
                }
                &.active:after {
                    border-color: #fff;
                }
            }
        }
        .s-list {
            padding: 16px 13px;

            li {
                padding: 0 3px;
            }
        }
        &-btn {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
            width: 48px;
            height: 64px;
            border: 0;
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6) 81%);
            outline: none;

            &:before {
                display: inline-block;
                width: 32px;
                height: 32px;
                border: 1px solid #f3f3f3;
                background: #fff;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
                content: "";
            }
            &:after {
                @include ico-arrow-down(8px, #bbb, 1px);
                @include transition(0.3s);
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -8px 0 0 -4px;
                content: "";
            }
        }
        &-toggle {
            .s-list {
                padding-right: 50px;
            }

            &.open {
                .s-list {
                    padding-bottom: 8px;

                    li {
                        display: inline-block;
                        padding-bottom: 8px;
                    }
                }
                .c-category__sub-btn {
                    &:after {
                        @include rotate(-45deg);
                        margin-top: -4px;
                    }
                }
            }
        }
    }
}
