// Footer New Design - 2017-08-28
@import "../sprites/sp_footer";
@import "../sprites/sp_chatbot";

.c-footer {
    position: relative;
    background: $color-gray-11;
    font-size: 13px;

    &__notice {
        display: block;
        position: relative;
        padding-right: 50px;
        margin-bottom: 10px;
        background: #fff;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        letter-spacing: $body-spacing;

        &-title {
            font-weight: bold;
            padding: 0 8px 0 16px;
            color: #333;
            float: left;
        }

        &-link {
            overflow: hidden;
            display: inherit;
            color: #666;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-more {
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            text-indent: -99999px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 18px;

                @include size(8px 8px);

                margin-top: -4px;
                border: 1px solid #666;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
            }
        }
    }

    &__info {
        display: block;
        padding: 0 0 70px;
        background: $color-gray-13;
    }

    &__app-download {
        letter-spacing: $body-spacing;
        position: relative;
        padding-bottom: 16px;
        margin: 0 16px;
        border-bottom: 1px solid $color-gray-11;

        .c-footer__app-title {
            text-align: center;
            padding: 16px 0;
            color: $color-gray-02;

            strong {
                font-weight: normal;
            }
        }
        .c-footer__app-list {
            display: flex;
            justify-content: center;

            li {
                position: relative;

                & + li {
                    margin-left: 49px;
                }
                a {
                    display: block;
                    position: relative;
                    width: 52px;
                    margin: 0 auto;
                    .c-footer__app-icon {
                        width: 52px;
                        height: 52px;
                    }
                    .c-footer__app-name {
                        display: block;
                        padding-top: 4px;
                        text-align: center;
                        color: $color-gray-04;
                    }
                }
            }
        }
    }

    // 앱 다운로드 영역 개선 - 2019-07-25 신규 영역 배포완료. 기타 페이지 확인 필요
    &__download {
        position: relative;
        background: $color-gray-13;
        height: 40px;
        margin: 0 8px;
        border-bottom: 1px solid #e3e3e3;

        &-item {
            float: left;
            position: relative;

            @include size(50% 40px);

            a {
                display: block;
                position: relative;
                padding: 0;
                height: 40px;
                line-height: 40px;
                color: #666;
                text-align: center;

                &:before {
                    content: "";
                    display: inline-block;
                    margin: -8px 5px -5px 0;
                    vertical-align: middle;
                }

                &:after {
                    @include size(1px 16px);

                    content: "";
                    display: block;
                    position: absolute;
                    top: 12px;
                    right: 0;
                    background: #dbdbdb;
                }

                &.app11st:before {
                    @include sprite-retina($sp_footer_appicon_11st);
                }

                &.shocking:before {
                    @include sprite-retina($sp_footer_appicon_shocking);
                }
            }

            &:last-child a:after {
                display: none;
            }
        }
    }

    &__legal {
        margin-bottom: 7px;
        text-align: center;
        font-size: 11px;

        &-link {
            position: relative;
            display: inline-block;
            margin-bottom: 2px;
            color: $color-gray-04;

            & + .c-footer__legal-link {
                margin-left: 9px;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: -5px;
                    width: 1px;
                    height: 8px;
                    margin-top: -5px;
                    background: #d8d8d8;
                    content: "";
                }
            }
        }
    }

    &__text,
    &__copyright {
        font-size: 10px;
        text-align: center;
        color: $color-gray-06;
    }

    &__text {
        padding: 0 50px;
        line-height: 1.5;
        &--fluid {
            max-width: 60%;
            word-break: keep-all;
            margin: 0 auto;
        }
    }

    &__copyright {
        letter-spacing: $body-spacing;
        padding-top: 2px;
        margin-bottom: 17px;
    }

    &__detail {
        text-align: center;
        letter-spacing: $body-spacing;

        .c-footer__text {
            padding: 0 16px;
        }
    }

    &__menu {
        margin-bottom: 10px;
        padding-top: 15px;
        text-align: center;

        &-item {
            display: inline-block;
            position: relative;
            color: $color-gray-02;
            line-height: 1.5;
            letter-spacing: $body-spacing;

            & + .c-footer__menu-item {
                margin-left: 17px;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: -10px;
                    width: 1px;
                    height: 10px;
                    margin-top: -6px;
                    background: #d8d8d8;
                    content: "";
                }
            }
        }
    }

    &__address {
        margin-bottom: 23px;
        font-size: 11px;
        letter-spacing: -0.2px;

        &-btn {
            border: 0;
            background: none;
            font-size: 11px;
            color: $color-gray-04;

            &:after {
                @include ico-arrow-down(5px, $color-gray-07);
                display: inline-block;
                margin: -2px 0 2px 4px;
                vertical-align: middle;
                transition: 0.3s;
                content: "";
            }
            &[aria-expanded="true"] {
                &:after {
                    margin: 0 0 0 4px;
                    @include rotate(315deg);
                }
            }
        }
        &-detail {
            display: none;
            margin: 10px 16px 0;
            padding: 16px;
            background: $color-gray-12;
            text-align: left;

            dl {
                overflow: hidden;
                line-height: 20px;

                dt {
                    float: left;
                    min-width: 70px;
                    margin-right: 16px;
                    color: $color-gray-07;
                }
                dd {
                    overflow: hidden;
                    color: $color-gray-04;
                }
            }
        }
        &-tel {
            color: $color-11st-red;
        }
    }

    [data-log-actionid-label="privacy_policy"] {
        color: $color-gray-01;
    }
}
