[data-ui-type="Store_ShortCut"] {
    letter-spacing: $body-spacing;
    background-color: $color-gray-11;
    padding: 8px 16px;
    a {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 41px;
        background-color: $color-gray-14;
        border-radius: 24px;
        text-align: center;
        font-size: $font-size-body1;
        span {
            position: relative;
            padding-left: 20px;
            color: #111;
            &:before {
                @include store-shortcut;
                position: absolute;
                left: -8px;
                top: -4px;
            }
            em {
                position: relative;
                font-style: normal;
                &:before {
                    position: absolute;
                    top: 5px;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    opacity: 0.1;
                    content: "";
                }
            }
        }
    }
}
