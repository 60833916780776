[data-ui-type="Store_Product_Basic"] {
    display: flex;
    flex-wrap: wrap;
    padding: 2px 10px 10px;

    .l-grid__col {
        padding: 10px 6px;
    }
    .c-card__gallery {
        .c-card__thumb {
            width: initial;
            height: initial;
        }
        .c-card__info {
            min-height: 76px;
            padding: 8px 0 0;

            .c-card__name {
                height: auto;
                max-height: 38px;
                margin-bottom: 5px;
                font-size: 14px;
            }
            .c-card__rate {
                vertical-align: baseline;

                strong {
                    font-weight: normal;
                }
            }
            .c-card__price {
                height: auto;
                vertical-align: baseline;
            }
            .c-flag-box {
                margin: 2px 0;
            }
            .c-card__name {
                em {
                    margin-right: 4px;
                    color: $color-11st-blue;
                }
            }
            .c-flag__item--ooah,.c-flag__item--freshtable {
                margin: 3px 4px 7px 0;
            }
        }
    }
}