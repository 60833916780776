[data-ui-type="Store_Category"] {
    background: $color-gray-11;
    letter-spacing: $body-spacing;

    .c-category {
        background: $color-gray-14;
        &-header {
            position: relative;
            border-bottom: 1px solid $color-gray-12;
            background-color: #fafafa;
            height: 48px;
            line-height: 48px;
            padding-left: 16px;
            font-size: 0;
            color: $color-gray-04;

            .c-category__item {
                display: inline-block;
                position: relative;
                margin-left: 22px;
                border: 0;
                background: none;
                font-size: $font-size-body1;
                color: $color-gray-04;
                line-height: 48px;
                outline: none;

                &:before {
                    height: 7px;
                    width: 7px;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    position: absolute;
                    top: 50%;
                    left: -16px;
                    margin-top: -4px;
                    border: 1px solid #999;
                    border-width: 0 1px 1px 0;
                    content: "";
                }

                &:first-child {
                    margin-left: 0;

                    &:before {
                        display: none;
                    }
                }
            }
            .c-category__toggle {
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 48px;
                border: 0;
                background: 0;
                outline: none;
                &:after {
                    @include ico-arrow-down(9px, #bbb, 1px);
                    @include transition(0.3s);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -8px 0 0 -4px;
                    content: "";
                }
                .sr-only {
                    position: absolute;
                }
            }
        }
        &-content {
            display: none;
        }
        &.open {
            .c-category-header {
                margin-bottom: -1px;
                border-bottom: 0;

                .c-category__toggle:after {
                    @include rotate(-45deg);
                    margin-top: -4px;
                }
            }
            .c-category-content {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
    .c-subcategory {
        li {
            .c-subcategory__link {
                height: 48px;
                line-height: 48px;
                padding-right: 15px;
                color: $color-gray-02;

                &.on {
                    font-weight: bold;
                    &:after {
                        display: none;
                    }
                }
            }
        }
        &--border {
            li {
                border-color: #f3f3f3;
            }
        }
    }
    .c-pagination {
        &__indicator {
            span {
                margin-top: -1px;
                margin-bottom: 1px;
                vertical-align: middle;

                & + span {
                    margin-left: 6px;
                }
                &.on {
                    background-color: $color-gray-02;
                }
            }
        }
    }
}
