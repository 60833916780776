@import "../../common/sprites/sp_eui";
@mixin zzim-store($switch:on) {
    content: '';
    display: block;
    border: 0;
    background-color: transparent;
    margin: 0 auto;
    @if $switch == "on" {
        @include sprite-retina($sp_eui_zim_on);
    }
    @else if $switch == "off" {
        @include sprite-retina($sp_eui_zim_off);
    }
}
