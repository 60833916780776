[data-ui-type="Store_Contents_Home_Swiper"] {
    .c-lazyload {
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), color-stop(80%, rgba(0, 0, 0, 0.28)), to(rgba(0, 0, 0, 0.39)));
            background: -webkit-linear-gradient(top, transparent 50%, rgba(0, 0, 0, 0.28) 80%, rgba(0, 0, 0, 0.39));
            background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.28) 80%, rgba(0, 0, 0, 0.39));
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .c-imgcover {
        &__text {
            padding: 0 24px 24px;
        }
        &__info {
            padding-right: 30px;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
            color: $color-gray-14;
            margin-bottom: 14px;

            .c-imgcover__title {
                overflow: hidden;
                font-size: 26px;
                font-weight: bold;
                margin-bottom: 4px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            span {
                display: block;
                overflow: hidden;
                padding-right: 16px;
                font-size: $font-size-subtitle1;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        &__more {
            display: block;
            width: 87px;
            height: 32px;
            line-height: 32px;
            border: 1px solid rgba(255, 255, 255, 0.6);
            background-color: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            text-align: center;
            color: $color-gray-14;
            font-size: $font-size-body2;
            span {
                position: relative;
                padding-right: 12px;
                &:after {
                    content: "";
                    display: block;
                    border: 1px solid #fff;
                    border-width: 0 1px 1px 0;
                    transform: rotate(315deg);
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 3px;
                    right: 2px;
                }
            }
        }
    }
    .c-pagination {
        position: absolute;
        bottom: 16px;
        right: 16px;
        z-index: 1;

        &__list {
            font-size: 11px;
            color: rgba(255, 255, 255, 0.4);

            .swiper-pagination-current {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}
