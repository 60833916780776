[data-ui-type="Store_Product_Video"] {
    @include thumbnail-contentslink;

    .c-headline {
        padding-top: 12px;
        background: none;
    }
    .c-headline__title {
        font-size: 20px;
        color: #fff;
    }
    .c-swiper {
        padding: 8px 0 20px;

        .w-swiper {
            padding: 0 16px;
        }
        li {
            width: 296px;
        }
        &.c-swiper__none {
            li {
                width: 100%;
            }
        }
    }
    .c-card__slide-video {
        width: 296px;
    }
}