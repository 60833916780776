.c-chip {
    display: inline-block;
    position: relative;
    height: 32px;
    margin: 0 6px 8px 0;
    padding: 0 12px;
    border: 1px solid $color-gray-10;
    border-radius: 16px;
    background: $color-gray-14;
    font-size: 14px;
    font-weight: normal;
    color: $color-gray-03;
    line-height: 32px;
    white-space: nowrap;
    box-sizing: border-box;
    outline: none;

    &[aria-selected="true"],
    &[aria-pressed="true"],
    &[aria-checked="true"],
    &--selected {
        border-color: $color-11st-red;
        background-color: #fff9fa;
        font-weight: bold;
        color: $color-11st-red;
    }

    &[disabled],
    &--disabled {
        border-color: $color-gray-11;
        color: $color-gray-09;

        .delete {
            &:before,
            &:after {
                background-color: $color-gray-07;
                opacity: 0.2;
            }
        }
    }

    &-shape {
        .c-chip {
            border-radius: 4px;
        }
    }

    &-input {
        a {
            display: block;
        }
        .c-chip {
            padding-right: 30px;
        }
        .delete {
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            background-color: transparent;
            vertical-align: top;
            outline: none;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                right: 58%;
                left: auto;
                width: 1px;
                height: 12px;
                margin: -6px 0 0 -1px;
                background: $color-gray-07;
                transform: rotate(45deg);
                content: "";
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    &-filter {
        .c-chip--selected {
            &:before {
                display: inline-block;
                width: 12px;
                height: 20px;
                margin: -3px 4px 3px 0;
                border: 1px solid $color-11st-red;
                border-width: 0 3px 3px 0;
                vertical-align: middle;
                transform: rotate(45deg) scale(0.5);
                content: "";
            }
        }
    }

    // checkbox, radio
    &-checkbox,
    &-radio {
        display: inline-block;
        position: relative;

        .c-chip {
            display: inline-block;
            position: relative;
            height: 32px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 32px;
            padding: 0 12px;
        }

        .c-checkbox {
            position: absolute;
            width: 24px;
            height: 24px;
            margin: 0;
            opacity: 0;
            margin-left: 20px;
        }

        .c-checkbox + .c-chip {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        .c-checkbox:checked + .c-chip {
            border-color: $color-11st-red;
            background-color: #fff9fa;
            font-weight: bold;
            color: $color-11st-red;
        }

        .c-checkbox:checked + .c-chip:before {
            position: relative;
            display: inline-block;
            width: 12px;
            height: 20px;
            margin: -3px 4px 3px 0;
            border: 1px solid $color-11st-red;
            border-width: 0 3px 3px 0;
            vertical-align: middle;
            transform: rotate(45deg) scale(0.5);
            background-color: transparent;
            content: "";
        }
    }

    // 라디오 버튼에는 체크 아이콘 표시 없음
    &-radio {
        .c-checkbox:checked + .c-chip:before {
            content: none;
        }
    }
}
