body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
select,
textarea,
p,
blockquote,
th,
td,
button {
    font-family: $font-family2;
}

body {
    letter-spacing: $body-spacing;
}

#wrap {
    max-width: 1280px;
    min-width: 318px;
    margin: auto;
}

.header-fixed,
.header-relative,
.is-fixed-header,
.is-relative-header {
    .c-gotop {
        bottom: 70px;
    }
}

.c-card__mov-button {
    @include sprite-retina($sp_eui_play_s);
    overflow: hidden;
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
    line-height: 200px;
    content: "";
}

.c-like__btn {
    position: absolute;
    width: 36px;
    height: 36px;
    border: 0;
    background: none;
    outline: none;

    @include zzim-product-svg(off);

    &[aria-pressed="true"],
    &.on {
        @include zzim-product-svg(on);
    }
}

.c-sns__info {
    padding: 12px 16px;

    &-link {
        display: flex;
        position: relative;
        padding-right: 14px;
        color: $color-gray-02;

        &:after {
            @include ico-arrow-right(7px, $color-gray-07);
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -4px;
            content: "";
        }
    }
    &-type {
        padding-right: 6px;
        font-size: 15px;
        line-height: 30px;

        &--instagram {
            &:before {
                @include sprite-retina($sp_store_new_instagram);
                display: inline-block;
                margin-right: 8px;
                vertical-align: top;
                content: "";
            }
        }
    }
    &-id {
        padding-top: 6px;
        font-size: 13px;
        line-height: 18px;
        word-break: break-all;
        flex: 1;
    }
}

.c-spinner {
    position: static;
    height: auto;
    padding: 200px 0;
    background: none;
    text-align: center;

    &:before {
        display: block;
        position: static;
        margin: 0 auto;
    }
}

.c-card__video {
    .c-card__thumb,
    .c-card__media {
        position: relative;
        padding-top: 100%;
    }

    .c-card__thumb {
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .c-card__media {
        background: $color-gray-01;

        video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            margin: auto;
            object-fit: cover;
        }
    }
}

[data-ui-type="Store_Relates"] {
    .c-relates {
        padding-right: 16px;
        padding-left: 16px;
        border-top: 0;

        &__arrow {
            display: flex;
            overflow: hidden;
            padding-right: 0;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:after {
                position: static;
                margin-left: 8px;
            }
            em {
                display: inline-block;
                overflow: hidden;
                max-width: calc(100% - 126px);
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

[data-ui-type="Store_Contents_Home_ReviewBasic"],
[data-ui-type="Store_Contents_Home_ReviewScroll"],
[data-ui-type="Store_Contents_Review"],
[data-ui-type="Store_Product_Basic"],
[data-ui-type="Store_Product_Card"],
[data-ui-type="Store_Product_ImgOverPrice"],
[data-ui-type="Store_Product_List"],
[data-ui-type="Store_Product_Recommend"],
[data-ui-type="Store_Product_Video"],
[data-ui-type="Store_ProductScroll_Basic"] {
    .c-card__thumb {
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.03);
            content: "";
        }
    }
}

.c-preview {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    padding-top: 100px;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, $color-gray-14);
        content: "";
    }
    &__text {
        padding: 40px 0 38px;
        background: $color-gray-14;
        font-size: 16px;
        color: $color-gray-02;
        line-height: 21px;
        text-align: center;
    }
    &-notice {
        position: relative;
        padding: 12px 12px 12px 10px;
        background: #e4f2fb;

        &__text {
            position: relative;
            padding-left: 26px;
            font-size: 12px;
            color: $color-gray-02;
            line-height: 1.5;

            &:before {
                @include sprite-retina($sp_store_new_info_b3);

                position: absolute;
                top: -2px;
                left: 0;
                content: "";
            }
        }
    }
}

.c-item-list {
    padding: 8px 0 0 30px;
    font-size: $font-size-body1;
    color: $color-gray-04;
    line-height: 20px;
}

.b-layer--storecoupon {
    .b-layer__modal-title {
        padding-top: 20px;
        font-size: $font-size-title;
        font-weight: bold;
        color: $color-gray-02;
    }
    p {
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
        font-size: $font-size-body1;
        color: $color-gray-02;
    }
    .b-layer__modal-content {
        overflow-y: scroll;
        .c-item-list {
            padding: 14px 0 0 0;
            line-height: 24px;
        }
    }
}
