@mixin loading-spinner-inner {
    padding: 10px 0;
    text-align: center;

    &:before {
        @include size(60px);
        display: inline-block;
        border-radius: 30px;
        background: #fff url("/MW/img/common/loading2.gif") no-repeat 50% 50%;
        background-size: 30px 30px;
        color: transparent;
        content: "";
    }
    & > span {
        @include sr-only;
    }
}
