.c-dialog {
    &__items {
        max-height: 282px;

        &:after {
            display: none;
        }
        .c-text {
            margin-top: -10px;
            padding: 0 0 10px 30px;
            font-size: 13px;
            color: #999;
            line-height: 19px;
        }
    }

    &__buttons {
        button {
            border-right-color: $color-gray-11;
            font-size: 15px;

            &:last-child {
                color: $color-gray-03;
            }
        }
    }
}

.c-popup {
    &__content {
        margin: 16px 0;
        padding: 0;

        span {
            font-size: $font-size-subtitle1;
        }
        strong {
            font-size: $font-size-subtitle2;
            font-weight: bold;
            color: $color-gray-02;

            span {
                display: inline-block;
                overflow: hidden;
                max-width: 80%;
                color: $color-11st-red;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: top;
            }
        }
        .c-popup__text-bg {
            span {
                overflow: initial;
                max-width: 100%;
            }
        }
        & + .c-popup__text {
            margin-top: -10px;
        }
        .c-popup__text {
            font-size: 15px;
            color: $color-gray-03;
            line-height: 22px;
        }
    }
    &__text {
        margin-top: 0;
        padding-bottom: 16px;
    }
    &__subtitle {
        margin: 16px 0 6px;
        font-size: $font-size-body1;

        & + .c-popup__text {
            padding-bottom: 0;
        }
    }
    &__shortcut {
        display: block;
        padding: 12px 0;
        overflow: hidden;
        color: $color-gray-02;
        text-align: left;

        img {
            display: block;
            width: 64px;
            height: 64px;
            float: left;
            border-radius: 24px;
        }
        &-text {
            display: block;
            font-size: $font-size-subtitle2;
            padding-top: 2px;
            padding-bottom: 10px;
            margin-left: 78px;
        }
        &-title {
            display: block;
            padding-bottom: 8px;
            margin-left: 78px;
            font-size: 18px;
            font-weight: bold;
        }
        &-name {
            padding: 11px 0 0 78px;
            font-size: 19px;
            color: $color-gray-01;
        }
        &-times {
            position: relative;
            display: inline-block;
            padding-top: 6px;
            margin-left: 14px;
            font-size: $font-size-body1;
            &:before {
                position: absolute;
                top: 8px;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: $color-11st-red;
                opacity: 0.1;
                content: "";
            }
            strong {
                font-weight: normal;
                color: $color-11st-red;
            }
        }
        &-info {
            display: block;
            padding-top: 20px;
            font-size: $font-size-subtitle2;
            color: $color-gray-02;
        }
    }
    &__close {
        top: 9px;
        right: 8px;
    }
    &__buttons {
        button {
            border-right-color: $color-gray-11;
        }
    }
    &__button-submit {
        margin-top: 4px;
    }
}

.checkbox-wrap {
    display: inline-block;
    .c-checkbox {
        & + label:before {
            border-radius: 12px;
        }
    }
    label {
        color: $color-gray-02;
    }
    & + .c-popup__content {
        margin-top: 28px;
    }
}

.b-layer__modal {
    .b-layer__modal-text {
        text-align: center;
        font-size: $font-size-body1;
        color: $color-gray-02;
        padding-top: 6px;
        span {
            position: relative;
            &:before {
                position: absolute;
                top: 5px;
                right: 0;
                left: 0;
                bottom: 0;
                opacity: 0.1;
                content: "";
            }
        }
    }
    .wrap_target {
        padding-top: 16px;

        &:first-child {
            padding-top: 0;
        }
    }
    .target_category {
        font-size: 0;

        li {
            display: inline-block;
            a {
                display: block;
                overflow: hidden;
                position: relative;
                height: 32px;
                margin: 8px 8px 0 0;
                padding: 0 16px;
                border-radius: 24px;
                border: 1px solid #e4e4e4;
                color: $color-gray-02;
                font-size: $font-size-subtitle2;
                line-height: 32px;
            }
        }
    }
    &.add-store {
        padding: 26px 16px 0;

        .c-popup__shortcut {
            width: 280px;
            margin: 0 auto;
        }
        .c-popup__checkbox {
            display: flex;
            width: 280px;
            margin: 0 auto;
            padding: 12px 0 10px;
            justify-content: space-between;

            .checkbox-wrap {
                label {
                    font-weight: bold;
                }
            }
        }
        .c-popup__buttons {
            margin: 20px -16px 0;
        }
    }

    &.modal-coupon {
        .b-layer__modal-title {
            top: 16px;
        }
        .b-layer__modal-content {
            min-height: 200px;
            padding-right: 8px;

            @media screen and (max-height: 460px) {
                & {
                    max-height: 228px;
                }
            }
            @media screen and (max-height: 360px) {
                & {
                    max-height: 208px;
                }
            }
        }
    }
}
