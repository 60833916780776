[data-ui-type="Store_Coupon_Empty"] {
    margin-top: 14px;
    padding: 12px 0 20px;
    border-top: 1px solid #f3f3f3;
    background: $color-gray-13;
    letter-spacing: $body-spacing;

    .c-coufon__empty {
        font-size: 15px;
        color: $color-gray-02;
        text-align: center;

        &:before {
            @include sprite-retina($sp_store_new_coupon);
            display: block;
            margin: 0 auto 2px;
            content: "";
        }
    }
}
