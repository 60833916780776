.c-tab-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    border-top: 1px solid #eee;
    background-color: #fff;

    &__list {
        display: flex;
        align-items: center;
    }
    &__item {
        flex: 1;
        height: 56px;
    }
    &__link {
        position: relative;
        display: block;
        padding-top: 9px;
        height: 100%;
        color: transparent;
    }
    &__menu {
        display: block;
        width: 36px;
        height: 36px;
        margin-right: auto;
        margin-left: auto;
        background-repeat: no-repeat;
        background-size: 100% auto;

        img {
            display: block;
            width: 18px;
            height: 18px;
            margin: 0 auto;
        }

        &--home {
            @include sprite-retina($sp_store_new_ic_tabbar_home);
        }
        &--menu {
            @include sprite-retina($sp_store_new_ic_tabbar_menu);
        }
        &--brand {
            @include sprite-retina($sp_store_new_ic_tabbar_brand);
        }
        &--like {
            @include sprite-retina($sp_store_new_ic_tabbar_like);
        }
        &--recent {
            padding-top: 9px;
            @include sprite-retina($sp_store_new_ic_tabbar_recent);
        }
    }

    &__item--selected {
        .c-tab-bar__link:before {
            content: "";
            position: absolute;
            top: -1px;
            right: 0;
            left: 0;
            height: 2px;
            background-color: $color-luxury;
        }
        .c-tab-bar__menu {
            &--home {
                @include sprite-retina($sp_store_new_ic_tabbar_home-on);
            }
            &--menu {
                @include sprite-retina($sp_store_new_ic_tabbar_menu-on);
            }
            &--brand {
                @include sprite-retina($sp_store_new_ic_tabbar_brand-on);
            }
            &--like {
                @include sprite-retina($sp_store_new_ic_tabbar_like-on);
            }
            &--recent {
                @include sprite-retina($sp_store_new_ic_tabbar_recent-on);
            }
        }
    }
}

// ios 앱에서 접근시 하단 여백 이슈 MPQA-66633
.luxury-app {
    .c-gotop,
    .c-goback {
        bottom: 100px;
    }
    .c-tabbar--luxury {
        top: 100vh;
        bottom: auto;
        padding-bottom: 32px;
        margin-top: -89px; // 하단탭바 높이 57 + 패딩 32
    }
}
