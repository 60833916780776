[data-ui-type="Store_Error"] {
    letter-spacing: $body-spacing;

    .c-error {
        padding: 120px 0;
        text-align: center;

        &__text {
            font-size: 13px;
            color: $color-gray-07;
            line-height: 1.4;

            strong {
                display: block;
                margin-bottom: 12px;
                font-size: 15px;
                font-weight: normal;
                color: $color-gray-02;

                &:before {
                    @include size(48px);
                    @include sprite-retina($sp_store_new_notice);

                    display: block;
                    margin: 0 auto 24px;
                    content: "";
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &.c-error__text-only {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}
