[data-ui-type="Store_Info_Summary"] {
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding-top: 28px;
    }

    .c-tag {
        margin: 0 4px 0 0;
        padding: 0;
        height: initial;
        border-color: transparent;
        background-color: transparent;
        line-height: initial;
        font-size: $font-size-subtitle1;
        &::nth-last-of-type(1) {
            margin: 0;
        }
        &-wrap {
            position: relative;
            &:before {
                position: absolute;
                top: 8px;
                right: 0;
                left: 0;
                bottom: 0;
                opacity: 0.1;
                content: "";
            }
        }
    }

    h3 {
        padding: 0 16px 28px;
        text-align: center;
        font-weight: normal;
        font-size: $font-size-subtitle1;
        color: $color-gray-02;
        span {
            display: inline-block;
        }
    }
    p {
        margin-top: -28px;
        padding: 10px 24px 25px;
        text-align: center;
        white-space: pre-line;
        font-size: $font-size-subtitle2;
        color: $color-gray-04;
        line-height: 22px;

        &:first-child {
            margin-top: 0;
            padding-top: 0;
        }
    }
    h4 {
        text-align: center;
        padding: 0 20px 12px;
        font-size: $font-size-subtitle2;
        color: $color-gray-02;
        font-weight: normal;
        button {
            @include sprite-retina($sp_store_new_info_s);
            display: inline-block;
            outline: none;
            margin: 0;
            padding: 0;
            border: 0;
            background-color: transparent;
            text-indent: -99999px;
            vertical-align: middle;
            margin-top: -2px;
        }
        & + .c-summary-button {
            margin-top: 16px;
        }
    }
    .c-summary-info {
        padding: 0 16px 28px;
        display: table;
        width: 100%;
        li {
            display: table-cell;
            width: 33.33333%;
            text-align: center;
            color: $color-gray-02;
            dt {
                padding-top: 6px;
                font-size: $font-size-body1;
            }
            dd {
                font-size: $font-size-title;
                font-weight: bold;
            }
        }

        &--inline {
            display: block;
            text-align: center;

            li {
                position: relative;
                display: inline-block;
                width: auto;

                & + li {
                    margin-left: 10px;
                    padding-left: 12px;

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 2px;
                        height: 2px;
                        margin-top: -1px;
                        background: $color-gray-09;
                        content: "";
                    }
                }

                dl {
                    display: inline-flex;
                    align-items: center;
                    flex-direction: row-reverse;
                }
                dt {
                    margin-right: 4px;
                    padding-top: 0;
                }
            }
        }
    }
    .c-summary-button {
        border-top: 1px solid #eee;
        height: 76px;
        padding: 16px 16px 0;

        &:first-child {
            margin-top: -28px;
            border-top: 0;
        }
        ul {
            display: table;
            width: 100%;
            li {
                display: table-cell;
                width: 25%;
                text-align: center;
            }
        }
        button {
            border: 0;
            padding: 0;
            margin: 0;
            background-color: #fafafa;
            text-indent: -99999px;
            display: block;
            width: 44px;
            height: 44px;
            border-radius: 24px;
            margin: 0 auto;
            position: relative;
            outline: none;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 10px;
                left: 10px;
            }
            &.c-button-zzim {
                &:before {
                    @include zzim-store(off);
                }
                &.on {
                    &:before {
                        @include zzim-store(on);
                    }
                }
            }
            &.c-button-add {
                &:before {
                    @include add-11talk(off);
                }
                &.on {
                    &:before {
                        @include add-11talk(on);
                    }
                }
            }
            &.c-button-share {
                &:before {
                    @include button-share;
                }
            }
            &.c-button-tel {
                &:before {
                    @include sprite-retina($sp_store_new_tel);
                }
            }
        }
    }
}
