[data-ui-type="Store_Product_Recommend"] {
    letter-spacing: $body-spacing;

    .c-recommend {
        padding: 8px 16px 4px;

        .c-card__text {
            display: block;
            margin-bottom: 4px;
            font-weight: normal;
        }
    }
    .c-card__counsel {
        padding: 0;
        font-size: 13px;
        color: #999;
    }
    .c-card__info {
        .c-card__price {
            vertical-align: baseline;
        }
        .c-flag-box {
            margin: 0 0 2px;
        }
        .c-card__name {
            em {
                margin-right: 4px;
                color: $color-11st-blue;
            }
        }
        .c-flag__item--ooah,.c-flag__item--freshtable {
            margin: 3px 4px 7px 0;
        }
    }
    .c-card__list {
        min-height: 100px;

        .c-card__info {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            margin-left: 100px;
            min-height: 100px;
            padding: 0 14px;

            .c-card__name {
                height: 38px;
                margin-bottom: 4px;
                font-size: 14px;
            }
        }
        .c-card__thumb {
            width: 100px;
            height: 100px;
            .c-card__text {
                position: absolute;
                top: 0;
                left: 0;
                padding: 4px 6px;
                font-size: 11px;
                color: $color-gray-14;
                text-align: center;
                background: rgba(255, 49, 161, 0.90);
            }
        }
    }
    .c-card__gallery {
        width: 144px;

        .c-card__thumb {
            width: 144px;
            height: 144px;
        }
        .c-card__info {
            min-height: initial !important; //개편이후 important 삭제예정
            padding: 10px 0 8px;

            .c-card__name {
                height: 36px;
                margin-bottom: 1px;
                font-size: 13px;
            }
            .c-card__price {
                height: 20px;
            }
            .c-prd-review {
                &__appraise {
                    padding-bottom: 6px;
                }
                &__rate-num {
                    font-size: $font-size-caption1;
                    color: $color-gray-07;
                    margin-left: 0;
                    vertical-align: bottom;
                }
            }
        }
    }
    .s-list {
        padding: 4px 10px;

        li {
            padding: 4px 4px;
        }
    }
}
