.c-coufon {
    position: relative;
    display: block;
    overflow: hidden;
    &--big {
        min-height: 98px;
    }
    &:before,
    &:after {
        display: inline-block;
        content: "";
        position: absolute;
        right: 64.5px;
        z-index: 1;
        background-color: #fafafa;
        border: 1px solid #ddd;
        width: 10px;
        height: 5px;
    }
    &:before {
        top: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top-color: #fafafa;
    }
    &:after {
        bottom: 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-color: #fafafa;
    }
    &__border {
        padding: 14px 87px 10px 20px;
        border: 1px solid $color-gray-10;
        background-color: $color-gray-14;
        font-size: 0;
    }
    &__text {
        font-size: 16px;
        color: $color-gray-02;

        .c-coufon__title {
            display: block;
            font-weight: bold;

            strong {
                font-size: $font-size-headline3;
                vertical-align: middle;
                padding-right: 4px;
            }
            span {
                display: inline-block;
                height: 24px;
                line-height: 24px;
                font-size: $font-size-body1;
            }
        }
        .c-coufon__sub {
            display: block;
            margin-top: 2px;
            font-size: 13px;
            line-height: 20px;
            color: $color-gray-02;
        }
    }
    &__button {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 70px;
        height: 100%;
        outline: none;
        font-size: 11px;
        color: #999;
        border: 0;
        border-left: 1px dashed $color-gray-10;
        background: transparent;
        .c-coufon__get {
            display: block;
            position: relative;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            text-indent: -99999px;
            margin: 4px auto 0;
            &:before {
                @include sprite-retina($sp_store_new_coupon_down);
                content: "";
                display: block;
                position: absolute;
                top: 6px;
                left: 6px;
            }
        }
        .c-coufon__qty {
            strong {
                font-size: 12px;
                font-weight: bold;
            }
        }
        .c-coufon__go {
            @include sprite-retina($sp_coupon_store_move);
            display: block;
            text-indent: -99999px;
            margin: 0 auto;
        }
        &:disabled {
            -webkit-tap-highlight-color: transparent;
            color: #bbb;
            .c-coufon__qty {
                color: #bbb;
            }
            .c-coufon__get {
                background-color: #ddd;
                &:before {
                    @include sprite-retina($sp_store_new_check_white);
                    top: 4px;
                    left: 4px;
                }
            }
        }
    }
    &__link {
        position: relative;
        display: inline-block;
        margin: 6px 0 0 0;
        padding-right: 24px;
        height: 18px;
        line-height: 18px;
        font-size: $font-size-body2;
        color: $color-gray-07;
        &:after {
            @include sprite-retina($sp_coupon_store_plus);
            content: "";
            position: absolute;
            right: 0;
            background-position: -27px -30px;
        }
    }
    &__addition {
        position: relative;
        display: inline-block;
        margin: 4px 0 0;
        padding-right: 17px;
        height: 18px;
        border: 0;
        background: none;
        line-height: 18px;
        font-size: $font-size-caption1;
        color: $color-gray-07;
        outline: none;

        &:after {
            @include sprite-retina($sp_store_new_info_s);
            content: "";
            position: absolute;
            right: 0;
            top: 2px;
        }
        &.selected {
            padding-right: 0;
            padding-left: 20px;
            color: $color-11st-red;
            &:before {
                @include sprite-retina($sp_coupon_store_check);
                content: "";
                position: absolute;
                left: 0;
                top: 2px;
            }
            &:after {
                display: none;
            }
        }
        .c-coufon__date + & {
            margin-left: 6px;
            padding-left: 8px;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                width: 2px;
                height: 2px;
                margin-top: -1px;
                background-color: $color-gray-10;
                content: "";
            }
        }
    }
    &__date {
        position: relative;
        display: inline-block;
        height: 18px;
        line-height: 18px;
        font-size: $font-size-caption1;
        color: $color-gray-07;
    }
}
