[data-ui-type="Store_Contents_Home_Coupon"] {
    letter-spacing: $body-spacing;
    padding: 16px 8px;
    background-color: $color-gray-13;
    border-top: 1px solid $color-gray-11;

    .swiper-slide {
        padding: 0 8px;
    }
    .c-pagination {
        &__indicator {
            margin-top: 12px;
            line-height: initial;

            span {
                vertical-align: bottom;

                & + span {
                    margin-left: 6px;
                }
                &.on {
                    background-color: $color-gray-02;
                }
            }
        }
    }
}
