$sprite-meta-common: (
    "name": 'common', // sprite 이름
    "width": 415, // sprite 판 너비
    "height": 414, // sprite 판 높이
);

$sprite-map-common: (
  '11pay': (
      "width": 43,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -106 - (51 - 43)/2,
  ),
  '11st': (
      "width": 56,
      "height": 24,
      "bg-top": -288 - (32 - 24)/2,
      "bg-left": -68 - (64 - 56)/2,
  ),
  'amazon': (
      "width": 112,
      "height": 34,
      "bg-top": -64 - (42 - 34)/2,
      "bg-left": 0 - (120 - 112)/2,
  ),
  'amazon_black': (
      "width": 112,
      "height": 34,
      "bg-top": -106 - (42 - 34)/2,
      "bg-left": 0 - (120 - 112)/2,
  ),
  'amazon_icon': (
      "width": 44,
      "height": 44,
      "bg-top": -328 - (52 - 44)/2,
      "bg-left": -54 - (52 - 44)/2,
  ),
  'amazon_white': (
      "width": 112,
      "height": 34,
      "bg-top": 0 - (42 - 34)/2,
      "bg-left": -163 - (120 - 112)/2,
  ),
  'amazon_white2': (
      "width": 112,
      "height": 34,
      "bg-top": -42 - (42 - 34)/2,
      "bg-left": -163 - (120 - 112)/2,
  ),
  'applecare': (
      "width": 60,
      "height": 60,
      "bg-top": -26 - (68 - 60)/2,
      "bg-left": -283 - (68 - 60)/2,
  ),
  'applestore': (
      "width": 154,
      "height": 28,
      "bg-top": -28 - (36 - 28)/2,
      "bg-left": 0 - (162 - 154)/2,
  ),
  'badge_official': (
      "width": 40,
      "height": 17,
      "bg-top": -350 - (25 - 17)/2,
      "bg-left": -106 - (48 - 40)/2,
  ),
  'beta': (
      "width": 25,
      "height": 11,
      "bg-top": -106 - (19 - 11)/2,
      "bg-left": -120 - (33 - 25)/2,
  ),
  'beta_active': (
      "width": 25,
      "height": 11,
      "bg-top": -125 - (19 - 11)/2,
      "bg-left": -120 - (33 - 25)/2,
  ),
  'coachmark': (
      "width": 12,
      "height": 12,
      "bg-top": -148 - (20 - 12)/2,
      "bg-left": -263 - (20 - 12)/2,
  ),
  'ic_chevron': (
      "width": 8,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -157 - (16 - 8)/2,
  ),
  'ic_info': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -173 - (22 - 14)/2,
  ),
  'ic_info_805e1c': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -195 - (22 - 14)/2,
  ),
  'ic_search': (
      "width": 20,
      "height": 20,
      "bg-top": -382 - (28 - 20)/2,
      "bg-left": -224 - (28 - 20)/2,
  ),
  'ic_timedeal': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -217 - (22 - 14)/2,
  ),
  'ic_timer': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -239 - (22 - 14)/2,
  ),
  'ico-arrow-expand': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": 0 - (32 - 24)/2,
  ),
  'ico-arrow-fold': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": -32 - (32 - 24)/2,
  ),
  'ico_info_blue': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -261 - (22 - 14)/2,
  ),
  'ico_info_gray': (
      "width": 15,
      "height": 14,
      "bg-top": -108 - (22 - 14)/2,
      "bg-left": -257 - (23 - 15)/2,
  ),
  'ico_info_red': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -283 - (22 - 14)/2,
  ),
  'ico_question': (
      "width": 14,
      "height": 14,
      "bg-top": -328 - (22 - 14)/2,
      "bg-left": -305 - (22 - 14)/2,
  ),
  'icon_checkmark': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": -64 - (32 - 24)/2,
  ),
  'icon_heart': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": -96 - (32 - 24)/2,
  ),
  'icon_heart_on': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": -128 - (32 - 24)/2,
  ),
  'icon_notice': (
      "width": 48,
      "height": 48,
      "bg-top": -262 - (56 - 48)/2,
      "bg-left": -353 - (56 - 48)/2,
  ),
  'icon_pause': (
      "width": 60,
      "height": 60,
      "bg-top": -94 - (68 - 60)/2,
      "bg-left": -283 - (68 - 60)/2,
  ),
  'icon_play': (
      "width": 60,
      "height": 60,
      "bg-top": -162 - (68 - 60)/2,
      "bg-left": -283 - (68 - 60)/2,
  ),
  'icon_relates': (
      "width": 60,
      "height": 60,
      "bg-top": -260 - (68 - 60)/2,
      "bg-left": 0 - (68 - 60)/2,
  ),
  'icon_soldout': (
      "width": 46,
      "height": 46,
      "bg-top": -328 - (54 - 46)/2,
      "bg-left": 0 - (54 - 46)/2,
  ),
  'icon_speaker': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": -160 - (32 - 24)/2,
  ),
  'icon_speaker_mute': (
      "width": 24,
      "height": 24,
      "bg-top": -382 - (32 - 24)/2,
      "bg-left": -192 - (32 - 24)/2,
  ),
  'logo_freshtable': (
      "width": 52,
      "height": 12,
      "bg-top": -106 - (20 - 12)/2,
      "bg-left": -353 - (60 - 52)/2,
  ),
  'logo_freshtable_filled': (
      "width": 58,
      "height": 20,
      "bg-top": -260 - (28 - 20)/2,
      "bg-left": -135 - (66 - 58)/2,
  ),
  'logo_martplus': (
      "width": 60,
      "height": 13,
      "bg-top": -230 - (21 - 13)/2,
      "bg-left": -283 - (68 - 60)/2,
  ),
  'logo_ooah': (
      "width": 36,
      "height": 12,
      "bg-top": -199 - (20 - 12)/2,
      "bg-left": -236 - (44 - 36)/2,
  ),
  'logo_ootd': (
      "width": 50,
      "height": 18,
      "bg-top": -194 - (26 - 18)/2,
      "bg-left": -353 - (58 - 50)/2,
  ),
  'logo_round_shooting_delivery': (
      "width": 52,
      "height": 20,
      "bg-top": -126 - (28 - 20)/2,
      "bg-left": -353 - (60 - 52)/2,
  ),
  'logo_round_shooting_freeze': (
      "width": 54,
      "height": 20,
      "bg-top": 0 - (28 - 20)/2,
      "bg-left": -353 - (62 - 54)/2,
  ),
  'logo_round_shooting_fresh': (
      "width": 54,
      "height": 21,
      "bg-top": -28 - (29 - 21)/2,
      "bg-left": -353 - (62 - 54)/2,
  ),
  'logo_round_shooting_install': (
      "width": 53,
      "height": 20,
      "bg-top": -57 - (28 - 20)/2,
      "bg-left": -353 - (61 - 53)/2,
  ),
  'logo_round_shooting_plus': (
      "width": 72,
      "height": 20,
      "bg-top": -171 - (28 - 20)/2,
      "bg-left": -89 - (80 - 72)/2,
  ),
  'logo_round_shooting_seller': (
      "width": 55,
      "height": 20,
      "bg-top": -260 - (28 - 20)/2,
      "bg-left": -201 - (63 - 55)/2,
  ),
  'logo_round_ssg_dawn': (
      "width": 65,
      "height": 20,
      "bg-top": -232 - (28 - 20)/2,
      "bg-left": 0 - (73 - 65)/2,
  ),
  'logo_round_ssg_emart': (
      "width": 65,
      "height": 20,
      "bg-top": -232 - (28 - 20)/2,
      "bg-left": -73 - (73 - 65)/2,
  ),
  'logo_skt': (
      "width": 59,
      "height": 20,
      "bg-top": -260 - (28 - 20)/2,
      "bg-left": -68 - (67 - 59)/2,
  ),
  'logo_sktmembership': (
      "width": 81,
      "height": 20,
      "bg-top": -171 - (28 - 20)/2,
      "bg-left": 0 - (89 - 81)/2,
  ),
  'logo_sktpass': (
      "width": 70,
      "height": 20,
      "bg-top": -199 - (28 - 20)/2,
      "bg-left": -81 - (78 - 70)/2,
  ),
  'logo_sktpass2': (
      "width": 69,
      "height": 20,
      "bg-top": -199 - (28 - 20)/2,
      "bg-left": -159 - (77 - 69)/2,
  ),
  'logo_ssg_dawn': (
      "width": 49,
      "height": 13,
      "bg-top": -220 - (21 - 13)/2,
      "bg-left": -353 - (57 - 49)/2,
  ),
  'logo_ssg_emart': (
      "width": 49,
      "height": 13,
      "bg-top": -241 - (21 - 13)/2,
      "bg-left": -353 - (57 - 49)/2,
  ),
  'logo_t': (
      "width": 28,
      "height": 28,
      "bg-top": -64 - (36 - 28)/2,
      "bg-left": -120 - (36 - 28)/2,
  ),
  'logo_tid': (
      "width": 73,
      "height": 25,
      "bg-top": -199 - (33 - 25)/2,
      "bg-left": 0 - (81 - 73)/2,
  ),
  'logo_tmembership': (
      "width": 155,
      "height": 20,
      "bg-top": 0 - (28 - 20)/2,
      "bg-left": 0 - (163 - 155)/2,
  ),
  'logo_tpluspoint': (
      "width": 82,
      "height": 13,
      "bg-top": -148 - (21 - 13)/2,
      "bg-left": -93 - (90 - 82)/2,
  ),
  'luxury': (
      "width": 72,
      "height": 12,
      "bg-top": -148 - (20 - 12)/2,
      "bg-left": -183 - (80 - 72)/2,
  ),
  'luxury_white': (
      "width": 72,
      "height": 12,
      "bg-top": -171 - (20 - 12)/2,
      "bg-left": -169 - (80 - 72)/2,
  ),
  'outlink': (
      "width": 9,
      "height": 11,
      "bg-top": -260 - (19 - 11)/2,
      "bg-left": -327 - (17 - 9)/2,
  ),
  'refurbly': (
      "width": 42,
      "height": 10,
      "bg-top": -288 - (18 - 10)/2,
      "bg-left": -195 - (50 - 42)/2,
  ),
  'reviewevent': (
      "width": 64,
      "height": 16,
      "bg-top": -232 - (24 - 16)/2,
      "bg-left": -146 - (72 - 64)/2,
  ),
  'seller_star': (
      "width": 12,
      "height": 12,
      "bg-top": -171 - (20 - 12)/2,
      "bg-left": -249 - (20 - 12)/2,
  ),
  'shooting_delivery': (
      "width": 55,
      "height": 13,
      "bg-top": -232 - (21 - 13)/2,
      "bg-left": -218 - (63 - 55)/2,
  ),
  'shooting_freeze': (
      "width": 51,
      "height": 12,
      "bg-top": -154 - (20 - 12)/2,
      "bg-left": -353 - (59 - 51)/2,
  ),
  'shooting_fresh': (
      "width": 55,
      "height": 13,
      "bg-top": -260 - (21 - 13)/2,
      "bg-left": -264 - (63 - 55)/2,
  ),
  'shooting_fresh_green': (
      "width": 51,
      "height": 12,
      "bg-top": -174 - (20 - 12)/2,
      "bg-left": -353 - (59 - 51)/2,
  ),
  'shooting_install': (
      "width": 53,
      "height": 13,
      "bg-top": -85 - (21 - 13)/2,
      "bg-left": -353 - (61 - 53)/2,
  ),
  'shooting_plus': (
      "width": 85,
      "height": 15,
      "bg-top": -148 - (23 - 15)/2,
      "bg-left": 0 - (93 - 85)/2,
  ),
  'shooting_seller': (
      "width": 55,
      "height": 13,
      "bg-top": -288 - (21 - 13)/2,
      "bg-left": -132 - (63 - 55)/2,
  ),
  'skpay': (
      "width": 62,
      "height": 18,
      "bg-top": 0 - (26 - 18)/2,
      "bg-left": -283 - (70 - 62)/2,
  ),
  'skpaymoney': (
      "width": 100,
      "height": 16,
      "bg-top": -84 - (24 - 16)/2,
      "bg-left": -163 - (108 - 100)/2,
  ),
  'skpaypoint': (
      "width": 86,
      "height": 16,
      "bg-top": -108 - (24 - 16)/2,
      "bg-left": -163 - (94 - 86)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-common($name, $width: null, $type: null) {
    $sp-w: map-get($sprite-meta-common, "width");
    $sp-h: map-get($sprite-meta-common, "height");
    $img: map-get($sprite-map-common, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;

    @if $type == null {
      background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      background-size: #{$bgw}px #{$bgh}px;
    } @else if $type == "mask" {
      mask-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      mask-size: #{$bgw}px #{$bgh}px;
    }
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-common($name: null, $tmp-width: null, $type: null) {
    display: inline-block;
    
    @if $type == null {
      vertical-align: top;
      background: url("/MW/img/svg/sprites/sp_common_2025326_154527.svg") no-repeat;
    } @else if $type == "mask" {
      mask-image: url("/MW/img/svg/sprites/sp_common_2025326_154527.svg");
    }
    @if $name != null {
      @include get-sprite-position-common($name, $width: $tmp-width, $type: $type);
    }
}