@mixin icon-flag($name-text) {
    overflow: hidden;
    height: 18px;
    padding: 2px 4px 1px;
    border: 1px solid #bbb;
    font-size: 11px;
    line-height: 14px;
    word-break: break-all;
    box-sizing: border-box;
    background-color: #fff;

    $flag-colors: (
        "f43142": #f43142,
        "ff0038": #ff0038,
        "ff334c": #ff334c,
        "f01b70": #f01b70,
        "f53fc6": #f53fc6,
        "6423ff": #6423ff,
        "0b83e6": #0b83e6,
        "00adf2": #00adf2,
        "249356": #249356,
        "ff8100": #ff8100,
        "b9830b": #b9830b,
        "687581": #687581,
        "426bd1": #426bd1,
        "0097a5": #0097a5,
        "00ae6f": #00ae6f,
        "3617ce": #3617ce,
        "000000": #000000,
        "f6fbfe": #f6fbfe,
        "5274ff": #5274ff,
        "0f0fd9": #0f0fd9,
        "ff7700": #ff7700,
    );

    @each $name-hex, $color in $flag-colors {
        &.#{$name-text}#{$name-hex} {
            position: relative;
            &:before {
                @include size(100%);
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba($color, 0.04);
            }
            color: $color;
            border-color: rgba($color, 0.4);
        }
    }
}
