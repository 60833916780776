[data-ui-type="Store_Coupon_List"] {
    letter-spacing: $body-spacing;
    .c-coufon {
        &:before,
        &:after {
            background-color: #fff;
        }
    }
    .c-coupon-header {
        position: relative;
        padding: 19px 16px 26px;
        font-size: $font-size-body1;
        color: $color-gray-02;
        p {
            strong {
                font-weight: normal;
            }
        }
        button {
            position: absolute;
            top: 9px;
            right: 16px;
            border: 1px solid #ddd;
            margin: 0;
            padding: 8px 12px;
            height: 36px;
            background-color: #fff;
            font-size: 14px;
            outline: none;
        }
    }
    .c-couponlist {
        border-top: 1px solid #f3f3f3;
        padding: 0 16px 16px 16px;
        h4 {
            padding: 20px 0 10px 0;
            font-size: $font-size-body1;
            color: $color-gray-04;
            font-weight: normal;
            &:before {
                display: inline-block;
                width: 2px;
                height: 2px;
                margin: -1px 4px 1px 0;
                background: #ddd;
                vertical-align: middle;
                content: "";
            }
        }
        ul {
            li {
                padding-bottom: 10px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}
