// Variables
$i: 0;
$skin-colors: ("linear-gradient(to right, #ff825e, #ff4b5b 49%, #ff5bb2)", "#ff31a1"), ("linear-gradient(to right, #fb90ad 0%, #b38fff)", "#ff749f"), ("linear-gradient(to right, #43b2ff, #6c70f5)", "#6786f0"), ("linear-gradient(to right, #13c5c4 2%, #41b77a 100%)", "#10b0af"), ("linear-gradient(to right, #9dc216 1%, #6b9e54 100%)", "#89b42f"), ("#f18c7a", "#eb7e6a"), ("#ff91b3", "#ff749f"), ("#b0957a", "#b0957a"), ("#708be6", "#6786f0"), ("#678157", "#678157"), ("#5b6167", "#f43142"), ("#111", "#f43142"), ("#fff", "#f43142"), ("linear-gradient(to right, #84d7e2, #a5e6cc 100%, #a6e7ce 100%)", "#54cabe");
@each $colors in $skin-colors {
    $i: $i + 1;
    $bg: nth($colors, 1);
    $color: nth($colors, 2);
    .skin_#{$i} {
        [data-ui-type="Store_GNB"] {
            background: #{$bg};
            .b-fixed {
                background: #{$bg};
            }
            .c-badge {
                color: #{$color};
            }
        }
        [data-ui-type="Store_Contents_Home_Intro"] {
            .c-storeinfo {
                &--rating {
                    li {
                        &:first-child {
                            dl {
                                color: #{$color};
                                dd {
                                    span {
                                        border-color: #{$color};
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        [data-ui-type="Store_Contents_Home_Notice"] {
            .c-list__item {
                &-link {
                    .c-list__text {
                        span {
                            color: #{$color};
                        }
                    }
                }
            }
        }
        [data-ui-type="Store_Contents_Home_Coupon"] {
            .c-coufon {
                &__title {
                    strong {
                        color: #{$color};
                    }
                }
                &__qty {
                    color: #{$color};
                }
                &__get {
                    background-color: #{$color};
                }
            }
        }
        [data-ui-type="Store_Product_Recommend"] {
            .c-recommend .c-card__text {
                color: #{$color};
            }
        }
        [data-ui-type="Store_PageTitle"] {
            .c-headline__subtext span:before {
                background: #{$color};
            }
        }
        [data-ui-type="Store_Product_Basic"],
        [data-ui-type="Store_ProductScroll_Basic"] {
            .c-card__gallery {
                .c-card__info {
                    .c-card__rate {
                        color: #{$color};
                    }
                }
            }
        }
        [data-ui-type="Store_Product_Card"] {
            .c-card__dealcard {
                .c-card__rate,
                .c-card__rate strong,
                .c-card__delivery .c-card__today em,
                .c-card__benefit .benefit em,
                .c-card__counsel em,
                .c-card__qty em {
                    color: #{$color};
                }
            }
        }
        [data-ui-type="Store_Product_Video"] {
            background: #{$bg};
        }
        [data-ui-type="Store_SearchBox"] {
            .c-searchlink__name {
                color: #{$color};
            }
        }
        [data-ui-type="Store_ShortCut"] {
            a {
                span {
                    em {
                        color: #{$color};
                        &:before {
                            background-color: #{$color};
                        }
                    }
                }
            }
        }
        [data-ui-type="Store_Coupon_List"] {
            .c-coufon {
                &__title {
                    strong {
                        color: #{$color};
                    }
                }
                &__qty {
                    color: #{$color};
                }
                &__get {
                    background-color: #{$color};
                }
            }
            .c-coupon-header {
                p {
                    strong {
                        color: #{$color};
                    }
                }
            }
        }
        [data-ui-type="Store_TabMenu"] {
            .c-nav {
                &__wrap {
                    .c-nav__item {
                        &.on {
                            a {
                                &:after {
                                    background-color: #{$color};
                                }
                            }
                        }
                    }
                }
            }
        }
        [data-ui-type="Store_Category"] {
            .c-category-header {
                .c-category__item {
                    &.on {
                        color: #{$color};
                    }
                }
            }
            .c-subcategory {
                li {
                    .c-subcategory__link {
                        &.on {
                            color: #{$color};
                        }
                    }
                }
            }
        }
        [data-ui-type="Store_Product_List"] {
            .c-cap {
                &:before {
                    border-color: #{$color};
                    border-bottom-color: transparent;
                }
            }
            .c-card {
                &__counsel {
                    em {
                        color: #{$color};
                    }
                }
                &__info {
                    .c-card__rate {
                        color: #{$color};
                    }
                    .c-card__benefit {
                        .benefit {
                            em {
                                color: #{$color};
                            }
                        }
                    }
                }
                &--box {
                    .c-radio:checked + label:before {
                        border-color: #{$color};
                        background-color: #{$color};
                    }
                }
            }
            .c-item-list {
                li {
                    em {
                        color: #{$color};
                    }
                }
            }
        }
        [data-ui-type="Store_Info_Summary"] {
            .c-tag {
                color: #{$color};
                &-wrap {
                    &:before {
                        background-color: #{$color};
                    }
                }
            }
        }
        [data-ui-type="Store_Info_Review"] {
            .c-store-review__point {
                .c-graph__bar {
                    &.on {
                        .c-graph__data {
                            background-color: #{$color};
                        }
                    }
                }
            }
        }
        [data-ui-type="Store_Category_Sub"] {
            .c-category__sub {
                .c-tag {
                    &.active {
                        background: #{$color};
                    }
                }
            }
        }
        [data-ui-type="Store_Info_Notice"] {
            .c-list {
                &__type {
                    &.highlight {
                        color: #{$color};
                        &:before {
                            background-color: #{$color};
                        }
                    }
                }
            }
            .c-pagination__list {
                .c-pagination__active {
                    a {
                        color: #{$color};
                    }
                }
            }
        }
        [data-ui-type="Store_Contents_Review"] {
            .c-tab__text {
                button[aria-selected="true"] {
                    color: #{$color};
                }
            }
            .c-checkbox-toggle {
                > input {
                    &:checked + em {
                        &:before,
                        &:after {
                            background: #{$color};
                        }
                    }
                }
            }
            .c-card-review__sumup {
                em {
                    color: #{$color};
                }
            }
            .c-card-review__detail {
                button {
                    color: #{$color};
                }
            }
        }
        [data-ui-type="Store_Product_Sorting"] {
            .c-checkbox:checked + .c-chip {
                border-color: #{$color};
                color: #{$color};
                &:before, &:after {
                    background: #{$color};
                }
            }
        }
        .c-relates__arrow {
            em {
                color: #{$color};
            }
        }
        .c-popup {
            &__buttons {
                .button-add {
                    color: #{$color};
                }
            }
            &__shortcut {
                &-title {
                    border-bottom: 1px solid #{$color};
                }
            }
        }
        .b-layer__modal {
            .b-layer__modal-text {
                span {
                    color: #{$color};
                    &:before {
                        background-color: #{$color};
                    }
                }
            }
            .c-item-list {
                li {
                    em {
                        color: #{$color};
                    }
                }
            }
        }
    }
}

// 조건문 테스트 필요
.skin_1 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin1);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin1);
        }
    }
}

.skin_2 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin2);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin2);
        }
    }
}

.skin_3 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin3);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin3);
        }
    }
}

.skin_4 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin4);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin4);
        }
    }
}

.skin_5 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin5);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin5);
        }
    }
}

.skin_6 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin6);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin6);
        }
    }
}

.skin_7 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin7);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin7);
        }
    }
}

.skin_8 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin8);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin8);
        }
    }
}

.skin_9 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin9);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin9);
        }
    }
}

.skin_10 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"],
    [data-ui-type="Store_Product_Recommend"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_skin10);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_skin10);
        }
    }
}
