@import "../../common/sprites/sp_eui";

@mixin thumbnail-contentslink {
    .c-card__mov__button-link {
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        text-indent: -99999px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            background-image: none;
            background-color: rgba(0, 0, 0, 0.3);
        }
        &:after {
            @include sprite-retina($sp_eui_contentslink);
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -40px 0 0 -30px;
        }
        .message {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: $font-size-body2;
            color: $color-gray-14;
            transform: translateX(-50%);
            margin-top: 28px;
            text-indent: 0;
            z-index: 1;
        }
    }
}
