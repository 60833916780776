[data-ui-type="Store_Info_Notice"] {
    letter-spacing: $body-spacing;

    h3 {
        padding: 24px 16px 12px;
        font-size: $font-size-subtitle1;
        color: #111;
    }
    .c-list {
        margin: 0 16px;
        width: initial;
        &__item-link {
            padding: 0;
            height: 50px;
            line-height: 50px;
            font-size: $font-size-body1;
        }
        &__type {
            position: relative;
            display: inline-block;
            width: 37px;
            height: 20px;
            margin-right: 8px;
            padding: 2px 0 4px;
            background-color: $color-gray-13;
            font-size: 11px;
            color: $color-gray-04;
            line-height: 16px;
            text-align: center;
            vertical-align: inherit;
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.1;
                border-radius: 2px;
            }
            &.highlight {
                & + .c-list__text {
                    font-weight: bold;
                }
            }
        }
        &.c-list--border {
            .c-list__item {
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    .c-relates {
        border-top: 0;

        &__arrow {
            &:after {
                transform: rotate(90deg);
            }
        }
    }
    .c-detail {
        &__tit {
            position: relative;
            padding: 24px 35px 10px 63px;
            line-height: 20px;

            .c-list__type {
                position: absolute;
                top: 24px;
                left: 16px;
            }
            .c-list__text {
                font-weight: bold;
            }
        }
        &__content {
            padding: 10px 16px 38px;
            font-size: 14px;
            color: #666;
            line-height: 24px;

            dl {
                margin-bottom: 6px;
                font-size: 13px;
                color: #999;

                dt,
                dd {
                    display: inline-block;
                }
                dt {
                    margin-right: 10px;
                }
            }
            img {
                max-width: 100%;
            }
        }
    }
}
