@import "../../pui/sprites/sp_kkuk";

[data-ui-type="Store_Contents_Review"] {
    background: $color-gray-11;

    .c-sorting {
        display: flex;
        height: 56px;
        border-bottom: 1px solid #eee;
        align-items: center;
        letter-spacing: $body-spacing;

        .c-tab__text {
            padding-left: 10px;
            font-size: 0;

            button {
                position: relative;
                padding: 0 6px;
                border: 0;
                background: none;
                font-size: 13px;
                color: #999;
                line-height: 36px;
                outline: none;

                & + button {
                    margin-left: 15px;

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: -8px;
                        width: 1px;
                        height: 10px;
                        margin-top: -5px;
                        background: #ddd;
                        content: "";
                    }
                }
            }
        }
        .c-sorting__filter-checkbutton {
            margin-left: auto;
            border-left: 0;
        }
    }
    .c-profile {
        line-height: 18px;

        &__name {
            display: inline-block;
            height: auto;
            font-size: 13px;
            font-weight: normal;
            line-height: 18px;
            vertical-align: top;
        }
        .c-prd-review__appraise {
            position: absolute;
            top: 50%;
            right: 0;
            line-height: 0;
            transform: translateY(-50%);
        }
    }
    .c-card-review__media {
        display: block;
        position: relative;

        // MPSR-61726 삭제예정
        .c-card--video {
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1;
            border: 0;
        }
        video {
            display: block;
            width: 100%;
            height: inherit;
        }
        // //MPSR-61726 삭제예정
        .c-card-review__thumb {
            position: relative;
            height: 100%;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .c-card-review__video {
            position: relative;
            height: 100%;

            video {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .c-card--certify {
            background-size: auto 100%;
        }
        .c-card__play {
            @include sprite-retina($sp_eui_play_b);
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            margin: -30px;
            text-indent: -99999px;

            &--replay {
                @include sprite-retina($sp_eui_replay);
            }
        }
    }
    .c-card-review__detail {
        position: relative;
        padding: 24px 16px 16px;
        font-size: 14px;
        color: $color-gray-02;

        .c-card-review__sumup {
            display: flex;
            margin-bottom: 10px;
            flex-wrap: wrap;

            li {
                overflow: hidden;
                flex: 0 0 50%;
            }
            em {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top;
            }
            span {
                display: inline-block;
                overflow: hidden;
                max-width: 65%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        p {
            line-height: 1.5;
            max-height: 66px;
            overflow: hidden;
        }
        button {
            padding: 4px 5px 4px 0;
            border: 0;
            background: none;
            font-size: 14px;
            outline: none;
        }
        .c-card-review__info-text {
            padding: 5px 0 10px;
            font-size: 15px;
            text-align: center;

            a {
                color: $color-11st-blue;
                text-decoration: underline;
            }
        }
        &.expanded {
            p {
                height: initial;
                max-height: initial;
            }
        }
    }
    .c-card-review__product {
        margin: 0 16px;
        padding-right: 50px;

        .c-card {
            &__thumb {
                img {
                    z-index: initial;
                }
            }
            &__title {
                margin: 0 0 4px;
                font-size: $font-size-body2;
            }
            &__info {
                padding-left: 12px;
            }
            &__option {
                height: 16px;
            }
        }
        .c-like__btn {
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
        }
    }
    article {
        margin-bottom: 10px;
        background-color: $color-gray-14;

        &:last-child {
            margin-bottom: 0;
        }
        header {
            position: relative;
            padding: 17px 16px 15px;
            border-bottom: 1px solid $color-gray-12;
        }
        section {
            padding-bottom: 16px;
        }
        footer {
            @include clearfix();

            margin: 0 16px;
            padding: 11px 0;
            border-top: 1px solid #f3f3f3;
        }
    }
    .c-feedback {
        position: relative;
        overflow: hidden;
        background-color: transparent;
        margin: 0;
        border: 0;
        height: 36px;
        line-height: 41px;
        font-size: $font-size-body2;
        color: $color-gray-03;
        padding-left: 38px;
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

        &--thumb {
            &:before {
                background-image: url("/MW/img/pui/sprites/kkuk_foot/kukkuk_off.svg");
                height: 184px;
                width: 65px;
                background-position: -15px -145px;
                background-size: 65px 184px;
                content: "";
                display: block;
                position: absolute;
                left: 7px;
                top: 5px;
            }
        }
        &.on {
            &.c-feedback--thumb {
                &:before {
                    background-image: url("/MW/img/pui/sprites/kkuk_foot/kukkuk_on.svg");
                    height: 184px;
                    width: 65px;
                    background-position: -15px -145px;
                    background-size: 65px 184px;
                }
            }
        }
        &.animating {
            overflow: visible;

            &.c-feedback--thumb {
                &:before {
                    background-image: none;
                }
            }
            i {
                opacity: 1;
            }
        }
        span {
            display: inline-block;
            position: relative;
            width: 26px;
            text-indent: -99999px;
            &:before {
                @include sprite-retina($sp_kkuk_kkuk);
                content: "";
                display: block;
                position: absolute;
                top: 7px;
            }
        }
        em {
            display: inline-block;
        }
        i {
            position: absolute;
            left: -8px;
            bottom: -8px;
            width: 65px;
            height: 184px;
            opacity: 0;
            transition: all 0.5s;
        }
        &-text {
            float: right;
            font-size: 13px;
            color: $color-gray-07;
            line-height: 36px;
        }
    }
    .c-pagination {
        position: absolute;
        bottom: 16px;
        right: 0;
        left: 0;
        z-index: 1;

        &__list {
            font-size: 11px;
            color: rgba(255, 255, 255, 0.4);

            span {
                display: inline-block;
                min-width: 8px;
            }
            .swiper-pagination-current {
                padding-right: 2px;
                color: rgba(255, 255, 255, 1);
            }
            .swiper-pagination-total {
                padding-left: 2px;
            }
        }
    }
}
