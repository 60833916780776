[data-ui-type="Store_SearchBox"] {
    letter-spacing: $body-spacing;
    position: relative;
    padding: 20px 16px;
    .c-searchlink {
        width: 100%;
        padding: 0;
        &__button {
            position: relative;
            display: block;
            height: 40px;
            background-color: #f3f3f3;
        }
        &__service {
            display: block;
            padding: 12px 16px;
            height: 40px;
            margin-right: 40px;
            font-size: $font-size-body1;
        }
        &__name {
            position: relative;
            padding-right: 10px;
            &:after {
                content: "";
                display: block;
                width: 1px;
                height: 12px;
                background-color: #ddd;
                position: absolute;
                right: 0;
                top: 2px;
            }
        }
        &__text {
            color: $color-gray-07;
            padding-left: 8px;
        }
        &__button-search {
            position: absolute;
            top: 4px;
            right: 8px;
            text-indent: -999999px;
            width: 32px;
            height: 32px;
            &:before {
                @include sprite-retina($sp_store_new_search);
                content: "";
                display: block;
                position: absolute;
            }
        }
    }
}
