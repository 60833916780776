@import "../../../svg/sp_luxury";
@import "../../../svg/sp_ootd";
@import "../../../svg/sp_mart";

.c-tabbar {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 1280px;
    height: 56px;
    z-index: 101;

    ul {
        display: table;
        width: 100%;
        height: 56px;
        .c-tabbar__item {
            position: relative;
            display: table-cell;
            width: 20%;
            height: 56px;
            vertical-align: top;
            .c-tabbar__link {
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                height: 56px;
                text-align: center;
                color: transparent;
                &:before {
                    content: "";
                    display: block;
                    width: 36px;
                    height: 36px;
                    margin: 10px auto;
                }
                &--back {
                    &:before {
                        @include sprite-retina($sp_gnb2_back);
                    }
                }
                &--side {
                    &:before {
                        @include sprite-retina($sp_gnb2_sidemenu);
                    }
                }
                &--home {
                    &:before {
                        @include sprite-retina($sp_gnb2_home);
                    }
                }
                &--my {
                    &:before {
                        @include sprite-retina($sp_gnb2_mypage);
                    }
                }
                &--items {
                    &:before {
                        display: none;
                    }
                    img {
                        display: block;
                        width: 18px;
                        height: 18px;
                        margin: 20px auto 15px;
                        object-fit: contain;
                    }
                    &:after {
                        @include size(44px);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        border: 3px solid $color-gray-03;
                        transform: translate(-50%, -50%) scale(0.5);
                        box-sizing: border-box;
                        content: "";
                    }
                }
                &--amazon {
                    &:before {
                        @include logo-svg($logo-amazon, 50px, 15px);
                        position: relative;
                        top: 13px;
                    }
                }
                &--shooting-delivery {
                    &:before {
                        @include sprite-retina($sp_gnb2_shootingdelivery);
                        position: relative;
                        top: 13px;
                    }
                }
                &--shorts {
                    &:before {
                        @include sprite-retina($sp_gnb2_shorts);
                    }
                }
                &--shorts-pre {
                    &:before {
                        @include sprite-retina($sp_gnb2_shorts_pre);
                    }
                }
                &--search {
                    &:before {
                        @include sprite-retina($sp_gnb2_search);
                    }
                }
                &--11st {
                    position: relative;
                    display: block;
                    color: transparent;

                    &:before {
                        @include sprite-retina($sp_gnb2_home);
                    }
                }
                &--luxury {
                    &:before {
                        @include get-sprite-luxury("ic-tabbar-home", 36);

                        display: block;
                    }
                }
                &--brand {
                    &:before {
                        @include get-sprite-luxury("ic-tabbar-brand", 36);

                        display: block;
                    }
                }
                &--like {
                    &:before {
                        @include get-sprite-luxury("ic-tabbar-like", 36);

                        display: block;
                    }
                }
                &--menu {
                    &:before {
                        @include get-sprite-luxury("ic-tabbar-menu", 36);

                        display: block;
                    }
                }
                &--recent {
                    &:before {
                        @include get-sprite-luxury("ic-tabbar-recent", 36);

                        display: block;
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 18px;
                        height: 18px;
                        margin-top: -1px;
                        transform: translate(-50%, -50%);
                    }
                }
                &--pick {
                    &:before {
                        @include get-sprite-luxury("ic-tabbar-pick", 36);

                        display: block;
                    }
                }
            }
            button.c-tabbar__link {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                margin: 8px auto;
                background-color: $color-11st-red;
                border-radius: 24px;
                background-image: linear-gradient(135deg, #ff6335, #fe1d30 64%, #ff31a1);
                border: 0;
                color: transparent;

                &:before {
                    @include sprite-retina($sp_gnb2_search-white);

                    content: "";
                    display: block;
                    margin: 0;
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    left: 8px;
                    top: 8px;
                }
            }
        }
    }
    &-lottie {
        position: fixed;
        left: 50%;
        bottom: 0;
        z-index: 101;
        width: 20%;
        height: 56px;
        transform: translateX(-50%);

        .c-tabbar__link--11festa {
            width: 100%;
            height: 72px;
            border: 0;
            background: none;
            margin-top: -14px;

            .lottie-container {
                @include size(72px);
                display: block;
                line-height: 1;
                margin: 0 auto;
                background-color: transparent;
            }
        }

        .lottie-image {
            display: block;
            position: relative;
            width: 72px;
            height: 72px;
            margin: 0 auto;

            &__image {
                display: block;
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }
            &__arrow {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    // 우아럭스 tabbar
    &--luxury {
        padding-bottom: env(safe-area-inset-bottom);
        border-top: 1px solid #eee;
        box-shadow: none;

        ul {
            display: flex;
            align-items: center;

            .c-tabbar__item {
                flex: 1;

                .c-tabbar__link:before {
                    margin-top: 9px;
                }
                &--selected {
                    &:before {
                        content: "";
                        position: absolute;
                        top: -1px;
                        right: 0;
                        left: 0;
                        height: 2px;
                        background-color: $color-luxury;
                    }
                    .c-tabbar__link {
                        &--luxury {
                            &:before {
                                @include get-sprite-luxury("ic-tabbar-home-on", 36);

                                display: block;
                            }
                        }
                        &--brand {
                            &:before {
                                @include get-sprite-luxury("ic-tabbar-brand-on", 36);

                                display: block;
                            }
                        }
                        &--like {
                            &:before {
                                @include get-sprite-luxury("ic-tabbar-like-on", 36);

                                display: block;
                            }
                        }
                        &--menu {
                            &:before {
                                @include get-sprite-luxury("ic-tabbar-menu-on", 36);

                                display: block;
                            }
                        }
                        &--recent {
                            &:before {
                                @include get-sprite-luxury("ic-tabbar-recent-on", 36);

                                display: block;
                            }
                        }
                        &--pick {
                            &:before {
                                @include get-sprite-luxury("ic-tabbar-pick-on", 36);

                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    // 패션추천 tabber
    &--ootd {
        z-index: 103; // 카테고리 레이어보다 위에 떠야해서 조정
        padding-bottom: env(safe-area-inset-bottom);
        border-top: 1px solid #eee;
        background: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(60px);
        box-shadow: none;

        ul {
            display: flex;
            align-items: center;

            .c-tabbar__item {
                flex: 1;

                .c-tabbar__link:before {
                    @include get-sprite-ootd("ic_tabbar_home", 36);

                    display: block;
                    margin: 9px auto 0;
                }
                .c-tabbar__link {
                    &--menu:before {
                        @include get-sprite-position-ootd("ic_tabbar_menu");
                    }
                    &--like:before {
                        @include get-sprite-position-ootd("ic_tabbar_like");
                    }
                    &--recent:before {
                        @include get-sprite-position-ootd("ic_tabbar_recent");
                    }
                }
                .c-tabbar__link--recent img {
                    width: 18.5px;
                    height: 18.5px;
                    border-radius: 1.5px;
                }
                &--selected {
                    &:before {
                        content: "";
                        position: absolute;
                        top: -1px;
                        right: 0;
                        left: 0;
                        height: 2px;
                        background-color: $color-11st-red;
                    }

                    .c-tabbar__link {
                        &--home:before {
                            @include get-sprite-position-ootd("ic_tabbar_home_on");
                        }
                        &--menu:before {
                            @include get-sprite-position-ootd("ic_tabbar_menu_on");
                        }
                        &--like:before {
                            @include get-sprite-position-ootd("ic_tabbar_like_on");
                        }
                        &--recent:before {
                            @include get-sprite-position-ootd("ic_tabbar_recent_on");
                        }
                    }
                }
            }
        }
    }

    // 마트플러스 tabbar
    &--mart {
        padding-bottom: env(safe-area-inset-bottom);
        box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.08);

        ul {
            display: flex;
            align-items: center;

            .c-tabbar__item {
                flex: 1;

                .c-tabbar__link:before {
                    @include get-sprite-mart("ic_tabbar_home", 36);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
                .c-tabbar__link {
                    &--menu:before {
                        @include get-sprite-position-mart("ic_tabbar_menu");
                    }
                    &--personal:before {
                        @include get-sprite-position-mart("ic_tabbar_personal");
                    }
                    &--recent:before {
                        @include get-sprite-position-mart("ic_tabbar_recent");
                    }
                }
                .c-tabbar__link--recent img {
                    width: 19.5px;
                    height: 19.5px;
                    margin-top: 0;
                    border-radius: 2px;
                    transform: translate(-51%, -49%);
                }
                &--selected {
                    .c-tabbar__link {
                        &--home:before {
                            @include get-sprite-position-mart("ic_tabbar_home_on");
                        }
                        &--menu:before {
                            @include get-sprite-position-mart("ic_tabbar_menu_on");
                        }
                        &--personal:before {
                            @include get-sprite-position-mart("ic_tabbar_personal_on");
                        }
                        &--recent:before {
                            @include get-sprite-position-mart("ic_tabbar_recent_on");
                        }
                    }
                }
            }
        }
    }
}

// 탭바에서 열리는 slideUp Modal
.c-modal--11festa {
    padding-top: 54px;
    max-width: 480px;
    outline: 0;
    .b-layer__modal-title {
        font-size: 17px;
        padding: 0 16px;
        height: 54px;
        line-height: 58px;
    }
    .w-swiper {
        padding-bottom: 20px;
    }
    .c-swiper {
        &__single {
            padding: 0 20px 20px;
        }
    }
    .swiper-wrapper {
        .swiper-slide {
            .c-lazyload {
                img {
                    width: 100%;
                }
            }
        }
    }
    .c-pagination {
        position: absolute;
        top: 10px;
        right: 24px;
    }
    .c-modal__mute {
        @include svg-icon($icon-mute, 24px, 24px);
        position: absolute;
        top: 66px;
        left: 12px;
        border: 0;
        z-index: 2;
        color: transparent;
        background-color: transparent;
    }
    .c-modal__speak {
        @include svg-icon($icon-speak, 24px, 24px);
        position: absolute;
        top: 66px;
        left: 12px;
        border: 0;
        z-index: 2;
        color: transparent;
        background-color: transparent;
    }
    .c-modal__controls {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;

        button {
            border: 0;
            z-index: 1;
            color: transparent;
            background-color: transparent;
        }
        .c-modal__buttons {
            display: flex;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            justify-content: center;
            button {
                margin: 0 10px;
            }
        }
        .c-modal__play {
            @include svg-icon($icon-play, 60px, 60px);
        }
        .c-modal__pause {
            @include svg-icon($icon-pause, 60px, 60px);
        }
        .c-modal__replay {
            @include svg-icon($icon-replay, 60px, 60px);
        }
    }
    .c-lazyload {
        &--ratio_16x9 {
            img {
                z-index: 1;
            }
            video {
                position: absolute !important;
                top: 0;
                left: 0;
                right: 0;
                width: initial;
                height: 100%;
                margin: 0 auto;
                animation: fadeInOpacity 0.2s ease-in;
            }
        }
    }
    .c-modal__link {
        @include size(81px 24px);
        position: absolute;
        right: 12px;
        bottom: 12px;
        padding: 5px 8px;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        border-radius: 2px;
        border: solid 1px rgba(0, 0, 0, 0.06);
        background-color: rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        z-index: 1;
        span {
            &::after {
                @include ico-arrow-right(6px, #fff, 1px);
                position: absolute;
                top: 8px;
                right: 7px;
            }
        }
    }
    .c-modal__close {
        position: absolute;
        top: 9px;
        right: 9px;
        width: 36px;
        height: 36px;
        z-index: 10;
        border: 0;
        background: 0 0;
        color: transparent;
        font-size: 10px;
        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 25px;
            margin-top: -13px;
            background: #333;
            content: "";
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
