@mixin loading-spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: transparent;
    text-indent: -9999px;

    &:before {
        @include size(60px);
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 200;
        margin: -30px 0 0 -30px;
        border-radius: 30px;
        background: #fff url("/MW/img/common/loading2.gif") no-repeat 50% 50%;
        background-size: 30px 30px;
        color: transparent;
        content: "";
    }
    & > span {
        @include sr-only;
    }
}
