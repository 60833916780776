[data-ui-type="Store_Contents_Home_ReviewBasic"] {
    padding: 2px 10px 14px;

    .l-grid__col {
        padding: 6px;
    }
    .c-card__list {
        min-height: 90px;

        .c-card__thumb {
            width: 90px;
            height: 90px;

            & + .c-prd-review {
                margin-left: 90px;
                padding: 1px 0 0 14px;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .c-card__mov-button {
            top: 3px;
            right: 3px;
        }
    }
    .c-prd-review__comment {
        font-size: 13px;
    }
}