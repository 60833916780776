[data-ui-type="Store_Contents_Home_Notice"] {
    padding: 0 16px;
    letter-spacing: $body-spacing;

    .c-list {
        &__item {
            &:last-child {
                .c-list__item-link {
                    border-bottom: 0;
                }
            }
            &-link {
                padding: 0;
                height: 48px;
                border-bottom: 1px solid $color-gray-12;
                line-height: 50px;
                font-size: $font-size-body1;
                color: $color-gray-02;

                &.benefit {
                    display: flex;
                    text-overflow: ellipsis;
                    white-space: normal;
                    align-items: center;

                    .c-list__type {
                        margin-right: 10px;
                        flex: 0 0 auto;
                    }
                    .c-list__text {
                        display: inline-block;
                        overflow: hidden;
                        max-width: 100%;
                        position: relative;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &:after {
                        @include sprite-retina($sp_store_new_info_s);
                        display: inline-block;
                        margin-left: 2px;
                        flex: 0 0 auto;
                        content: "";
                    }
                }
                .c-list__type {
                    display: inline-block;
                    width: 37px;
                    height: 20px;
                    margin-right: 6px;
                    padding: 2px 0 4px;
                    border-radius: 2px;
                    background-color: $color-gray-13;
                    line-height: 16px;
                    color: $color-gray-04;
                    text-align: center;
                    font-size: $font-size-caption2;
                    vertical-align: inherit;
                }
                .c-list__text {
                    .new {
                        display: inline-block;
                        margin: -3px 0 3px;
                        font-size: 10px;
                        font-weight: bold;
                        line-height: 13px;
                        vertical-align: middle;
                    }
                }
            }
        }
        &.c-list--arrow {
            .c-list__item-link {
                padding-right: 14px;

                &:after {
                    @include size(7px);
                    right: 4px;
                }
            }
        }
    }
}
