.skin_13 {
    [data-ui-type="Store_Product_Video"] {
        .c-headline__title {
            color: #000;
        }
    }
}

.skin_11,
.skin_12,
.skin_13 {
    [data-ui-type="Store_Contents_Home_ReviewBasic"],
    [data-ui-type="Store_Contents_Home_ReviewScroll"],
    [data-ui-type="Store_Product_List"],
    [data-ui-type="Store_Contents_Review"] {
        .c-prd-review__rate-value {
            @include sprite-retina($sp_store_review_star_exception);
        }
        .c-prd-review__ratesmall-value {
            @include sprite-retina($sp_store_review_small_star_exception);
        }
    }
}