.c-layer-search {
    // 공통에 추가하려 하였으나, 현재 서비스별로 필요한 컴포넌트 사용중.
    // 검색레이어에서 필요한 컴포넌트 import
    // .c-layer-search 내에서 스타일이 적용될 수 있도록 해당 위치에 배치함.
    // 추후 common CSS 관련 협의가 완료된 후 개선되면, 해당 import 삭제 여부 결정 예정.
    @import "../../../eui/component/chip";
    @import "../../../eui/component/headline";
    @import "../../../eui/component/dialog";
    @import "../../../skpui/util/lazyload";
    @import "../../../skpui/component/card-item";
    @import "../../../skpui/component/component_setting";
    @import "../../../skpui/component/pagination";
    @import "../../../svg/sp_common";
    display: block;
    background-color: $color-gray-14;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: fixed;
    width: 100%;
    max-width: 1280px;
    height: 94vh;
    bottom: 0;
    overflow: hidden;
    z-index: 105;
    * {
        font-family: $font-family3;
    }
    &__holder {
        display: block;
        position: relative;
        width: 100%;
        height: 16px;
        cursor: row-resize;
        z-index: 2;
        background-color: $color-gray-14;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 6px;
            transform: translateX(-50%);
            display: block;
            width: 48px;
            height: 4px;
            border-radius: 2px;
            background-color: $color-gray-10;
            box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05);
        }
    }
    &__header {
        display: block;
        position: relative;
        padding: 0 16px;
        height: 58px;
        z-index: 1;
        background-color: $color-gray-14;
        -webkit-backface-visibility: hidden;

        &:after {
            content: "";
            display: block;
            position: relative;
            bottom: 2px;
            width: 100%;
            height: 2px;
            background: $color-11st-gradient;
            animation-fill-mode: forwards;
        }
    }
    &__searchbox {
        display: flex;
        position: relative;
        padding: 14px 48px 8px 0px;
        align-items: center;
        z-index: 1;
        border-bottom: 2px solid transparent;

        input[type="search"] {
            width: 100%;
            height: 36px;
            padding: 0 24px 0 0;
            border: 0;
            font-size: $font-size-title;
            font-weight: bold;
            outline: none;
            color: $color-gray-01;
            &::placeholder {
                color: $color-gray-06;
                font-weight: normal;

                @media screen and (max-width: 360px) {
                    font-size: 5vw;
                }
            }
        }

        button {
            position: absolute;
            background-color: transparent;
            border: 0;

            &.c-layer-search__button {
                display: block;
                width: 36px;
                height: 36px;
                position: absolute;
                right: 6px;
                top: 14px;
                text-indent: -99999px;
                &:before {
                    @include sprite-retina($sp_gnb2_search);
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            &.c-layer-search__button-all {
                position: absolute;
                font-size: $font-size-subtitle2;
                color: $color-11st-red;

                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 24px;
                    background-color: $color-gray-11;
                    position: absolute;
                    left: -9px;
                    top: -3px;
                }
            }
            &.c-layer-close__button {
                position: static;
                flex-shrink: 0;
                margin-left: -6px;
                color: transparent;
                display: block;
                overflow: hidden;
                left: -6px;
                top: 14px;
                width: 36px;
                height: 36px;
                @include sprite-retina($sp_gnb2_back);

                & + input[type="search"] {
                    // margin-left: 32px;
                    width: calc(100% - 32px);
                }
            }
        }
        .del {
            @include sprite-retina($sp_gnb2_cancel);
            display: block;
            position: absolute;
            right: 48px;
            top: 19px;
            background-color: transparent;
            text-indent: -99999px;
            border: 0;
        }
        .c-layer-search__inner {
            display: block;
            height: 32px;
            position: relative;
            margin-right: 8px;
            padding: 0 31px 0 12px;
            background: rgba($color-11st-red, 0.04);
            border: 1px solid rgba($color-11st-red, 0.1);
            border-radius: 32px;
            color: $color-11st-red;
            font-size: 15px;
            font-weight: bold;
            line-height: 32px;
            white-space: nowrap;
            box-sizing: border-box;
            &-delete {
                overflow: hidden;
                position: absolute;
                top: -2px;
                right: 0;
                border: none;
                background: none;
                width: 32px;
                height: 32px;
                color: transparent;
                text-indent: -1000px;

                @include btn-close-x($size: 13px, $weight: 1px, $color: #666);
            }
        }
    }
    // 브랜드몰(일부), 스토어, 아울렛, 티켓에서만 쓰고 있는 스타일 (S)
    &.c-layer-search--vertical {
        .c-layer-search__searchbox {
            padding: 14px 118px 8px 0;

            .c-layer-search__button {
                right: 82px;
            }

            .c-layer-search__button-all {
                right: 15px;
                top: 23px;
            }
            .del {
                right: 119px;
                top: 19px;
            }
        }
    }
    // 브랜드몰(일부), 스토어, 아울렛, 티켓에서만 쓰고 있는 스타일 (E)
    &__contents {
        display: block;
        position: relative;
    }
    &__yscroll {
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        max-height: calc(94vh - 74px); // 레이어 높이(94vh) - 터치영역(16px) - 검색창 높이(58px)
        -webkit-overflow-scrolling: touch;

        &.c-layer-search__gap {
            padding-top: 30px;
        }
    }
    // [M] (start) AS-IS UI를 사용하는 영역이 존재하므로 해당 영역 삭제는 추후 다시 검토
    // 브랜드몰(일부), 스토어, 아울렛, 티켓, 장애인고용공단
    &__scroll {
        position: relative;
        &.c-layer-search__gap {
            padding-top: 30px;
        }
    }
    &__keyword {
        display: block;
        letter-spacing: $body-spacing;
        .c-layer-search__pagination {
            display: block;
            text-align: center;
            padding: 14px 0 10px;
            font-size: 0;
            .swiper-pagination-bullet {
                display: inline-block;
                overflow: hidden;
                width: 6px;
                height: 6px;
                margin: 0 2px;
                background: rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                color: transparent;
                font-size: 5px;
                vertical-align: top;
                opacity: 1;
                &.on {
                    background: $color-gray-01;
                }
            }
        }
        .c-layer-search-swiper {
            margin: 0 8px;
            .swiper-slide {
                display: block;
                width: 84% !important;
                outline: none;

                &:focus:not(:focus-visible) {
                    outline: none;
                }
                &-prev {
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20%;
                        height: 100%;
                        background: transparent;
                        top: 0;
                        right: 0;
                        z-index: 2;
                    }
                }
                &-next {
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20%;
                        height: 100%;
                        background: transparent;
                        top: 0;
                        left: 0;
                        z-index: 2;
                    }
                }
            }
            & + .c-layer-search__pagination.swiper-pagination-bullets {
                position: absolute;
                top: -30px;
                width: 100%;
            }
        }
        .b-search-list {
            overflow: hidden;
            margin: 4px 4px;
            background-color: $color-gray-14;
            border-radius: 2px;
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.28), 0 4px 7px 0 rgba(0, 0, 0, 0.06);
            &__title {
                padding: 0 16px;
                margin: 0;
                background-color: $color-gray-13;
                border-bottom: 1px solid $color-gray-12;
                color: $color-gray-02;
                font-size: $font-size-body2;
                font-weight: bold;
                height: 43px;
                line-height: 44px;
            }
            .b-search-list-box {
                display: block;
                padding: 0 12px;
                ul {
                    li {
                        position: relative;
                        border-bottom: 1px solid $color-gray-12;
                        &:last-child {
                            border-bottom: 0;
                        }
                        a {
                            display: block;
                            overflow: hidden;
                            padding-left: 0;
                            padding-right: 26px;
                            height: 46px;
                            color: $color-gray-02;
                            font-size: $font-size-subtitle1;
                            line-height: 50px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .del-item {
                            overflow: hidden;
                            width: 42px;
                            height: 46px;
                            border: none;
                            color: transparent;
                            background-color: transparent;
                            position: absolute;
                            right: -16px;
                            top: 0;
                            outline: none;
                            &:after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 36px;
                                height: 36px;
                                margin: -18px 0 0 -18px;
                                background-image: linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc);
                                background-repeat: no-repeat;
                                background-position: 50% 50%;
                                background-size: 1px 24px, 24px 1px;
                                border-radius: 50%;
                                transform: rotate(45deg) scale(0.5);
                            }
                        }
                    }
                    &.c-layer-search__keyword-amazon {
                        li {
                            a {
                                strong {
                                    color: $color-amazon-bluegreen;
                                }
                            }
                        }
                    }
                }
                &__none {
                    padding: 135px 0;
                    color: $color-gray-06;
                    font-size: 13px;
                    line-height: 18px;
                    text-align: center;
                }
            }
            .b-search-bottom {
                overflow: hidden;
                border-top: 1px solid $color-gray-12;
                height: 40px;
                line-height: 40px;
                text-align: right;
                background-color: $color-gray-14;
                button {
                    height: 40px;
                    padding: 0 16px;
                    background-color: transparent;
                    border: 0;
                    color: $color-gray-04;
                    font-size: 12px;
                }
                .link-more {
                    position: relative;
                    font-size: 12px;
                    margin-right: 14px;
                    padding-right: 16px;
                    color: $color-gray-06;
                    &:after {
                        content: "";
                        display: block;
                        border: 1px solid #ccc;
                        border-width: 0 1px 1px 0;
                        position: absolute;
                        top: 3px;
                        right: 4px;
                        height: 7px;
                        width: 7px;
                        transform: rotate(314deg);
                    }
                }
                span {
                    position: relative;
                    font-size: 12px;
                    height: 42px;
                    padding-right: 16px;
                    color: $color-gray-07;
                }
            }
            &__popular {
                .b-search-list-box {
                    ul {
                        li {
                            a {
                                display: flex;
                                white-space: normal;
                                text-overflow: initial;

                                strong {
                                    margin-right: 10px;
                                    font-weight: normal;
                                    font-style: italic;
                                    color: $color-11st-red;
                                }
                                em {
                                    overflow: hidden;
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    transform: translateY(-50%);
                                    &.up {
                                        @include ranking-arrow(up);
                                        margin-right: 6px;
                                    }
                                    &.down {
                                        @include ranking-arrow(down);
                                        margin-right: 6px;
                                    }
                                    &.same {
                                        @include ranking-arrow(same);
                                        margin-right: 5px;
                                    }
                                    &.new {
                                        overflow: hidden;
                                        right: -1px;
                                        width: 25px;
                                        height: 10px;
                                        text-indent: 0;
                                        font-weight: bold;

                                        &:before {
                                            display: block;
                                            font-size: 10px;
                                            color: $color-11st-red;
                                            line-height: 10px;
                                            text-align: right;
                                            content: "NEW";
                                        }
                                    }
                                }
                                .description {
                                    display: inline-block;
                                    overflow: hidden;
                                    margin-left: 6px;
                                    font-size: 12px;
                                    color: $color-gray-06;
                                    line-height: 50px;
                                    vertical-align: top;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    flex: 1;
                                }
                            }
                        }
                    }
                }
            }
            &__viewed {
                .b-search-list-box {
                    ul {
                        li {
                            .c-card {
                                box-sizing: border-box;
                                min-height: initial;
                                height: 92px;
                                padding: 16px 0;
                                margin-right: 0;
                                line-height: initial;
                                font-size: $font-size-body2;
                                &__thumb {
                                    display: block;
                                    position: absolute;
                                    width: 60px;
                                    height: 60px;

                                    &:after {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        border: 1px solid rgba(0, 0, 0, 0.05);
                                        content: "";
                                    }
                                    img {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        width: 100%;
                                        height: 100%;
                                        max-width: initial;
                                        max-height: initial;
                                        object-fit: contain;
                                    }
                                }
                                &__info {
                                    display: block;
                                    position: relative;
                                    background-color: #fff;
                                    padding: 0 0 6px 6px;
                                    margin-left: 70px;
                                }
                                &__title {
                                    @include text-ellipsis-multiple(2);
                                    margin-top: 0;
                                    max-height: 34px;
                                    font-size: 14px;
                                    color: $color-gray-03;
                                    line-height: 17px;
                                }
                                &__price {
                                    padding-top: 3px;
                                    font-size: $font-size-body1;
                                    strong {
                                        font-size: $font-size-subtitle1;
                                    }
                                    &.stat-bold {
                                        font-size: $font-size-body2;
                                        color: $color-11st-red;
                                    }
                                    &.stat-bold2 {
                                        font-size: $font-size-body2;
                                        color: $color-11st-blue;
                                    }
                                }
                                &__list--amazon {
                                    .c-nation {
                                        margin-bottom: 4px;

                                        .amazon {
                                            @include logo-svg($logo-amazon, 47px, 14px);
                                            margin-top: 5px;
                                            margin-bottom: -5px;
                                        }
                                    }
                                    .c-card__title {
                                        max-height: 17px;
                                        -webkit-line-clamp: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // [M] (end) AS-IS UI를 사용하는 영역이 존재하므로 해당 영역 삭제는 추후 다시 검토
    &__autocomplete {
        display: block;
        overflow: hidden;
        min-height: calc(94vh - 74px); // 레이어 높이(94vh) - 터치영역(16px) - 검색창 높이(58px)
        .c-layer-search__keyword-result {
            display: block;
            height: 45px;
            line-height: 45px;
            margin: 0 16px;
            border-bottom: 1px solid $color-gray-11;
            a {
                display: inline-block;
                em {
                    display: inline-block;
                    height: 18px;
                    padding: 0 7px;
                    margin-right: 6px;
                    border: 1px solid $color-gray-03;
                    border-radius: 9px;
                    font-size: 11px;
                    line-height: 18px;
                    vertical-align: middle;
                }
                span {
                    font-size: 14px;
                    color: $color-gray-04;
                    strong {
                        color: $color-gray-03;
                    }
                }
            }
        }
        .c-layer-search__category {
            padding: 10px 0;
            margin: 0 16px;
            background-color: $color-gray-14;
            border-bottom: 1px solid $color-gray-11;
            h2 {
                line-height: 20px;
                font-size: $font-size-subtitle2;
                color: $color-gray-02;
                font-weight: normal;
            }
            &-list {
                li {
                    margin-top: 7px;
                    a {
                        display: block;
                        overflow: hidden;
                        position: relative;
                        height: 20px;
                        padding-left: 21px;
                        color: #5f7fe4;
                        font-size: $font-size-subtitle2;
                        line-height: 20px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 20px;
                            height: 19px;
                            background: url("/MW/img/common/kword.png") no-repeat 0 0;
                            background-size: 20px 175px;
                        }
                    }
                    &.m2 a:before {
                        background-position: 0 -20px;
                    }
                    &.m3 a:before {
                        background-position: 0 -40px;
                    }
                    &.m4 a:before {
                        background-position: 0 -60px;
                    }
                    &.m5 a:before {
                        background-position: 0 -80px;
                    }
                    &.m6 a:before {
                        background-position: 0 -100px;
                    }
                    &.m7 a:before {
                        background-position: 0 -120px;
                    }
                    &.m8 a:before {
                        background-position: 0 -140px;
                    }
                }
            }
        }
        .c-layer-search__vertical {
            display: block;
            height: 40px;
            line-height: 47px;
            a {
                display: inline-block;
                em {
                    display: inline-block;
                    height: 18px;
                    padding: 0 7px;
                    margin-right: 6px;
                    border: 1px solid $color-gray-03;
                    border-radius: 9px;
                    font-size: 11px;
                    line-height: 18px;
                    vertical-align: middle;
                }
                span {
                    font-size: 14px;
                    color: $color-gray-04;
                    strong {
                        color: $color-gray-03;
                    }
                }
            }
        }
        &-type {
            padding: 23px 16px 3px;
            font-size: 14px;
            color: $color-gray-02;
        }
        &-list {
            background-color: $color-gray-14;
            margin: 0 16px;
            li {
                position: relative;
                border-bottom: 1px solid $color-gray-11;
                a {
                    display: block;
                    overflow: hidden;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 40px 0 0;
                    color: $color-11st-red;
                    font-size: $font-size-subtitle2;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    strong {
                        color: $color-gray-03;
                        font-weight: normal;
                    }
                }
                button {
                    width: 40px;
                    height: 48px;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    right: -6px;
                    border: 0;
                    background-color: transparent;
                    color: transparent;
                    &:before {
                        content: "";
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        top: 12px;
                        right: 5px;
                        background: url("/MW/img/common/autocomplete.png") no-repeat 0 0;
                        background-size: 24px 24px;
                    }
                }
                p {
                    display: block;
                    overflow: hidden;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 40px 0 0;
                }
            }
        }
        .c-layer-search__close {
            position: relative;
            float: right;
            padding: 0 24px 0 10px;
            margin: 17px 16px 0 0;
            height: 28px;
            border: 0;
            background-color: $color-gray-14;
            color: $color-gray-02;
            font-size: $font-size-body2;
            border: 1px solid $color-gray-09;
            outline: none;
            &:before,
            &:after {
                content: "";
                height: 13px;
                width: 1px;
                position: absolute;
                left: auto;
                right: 14px;
                top: 6px;
                background: $color-gray-07;
            }
            &:before {
                @include rotate(45deg);
            }
            &:after {
                @include rotate(-45deg);
            }
        }
    }
    &.modal-bottom {
        top: 100vh;
        will-change: transform;
        will-change: top;
        &.slideToBottom {
            transform: translate3d(-50%, -100%, 0);
        }
    }
    &.c-layer-search--amazon {
        .c-layer-search__header {
            &::after {
                background: $color-amazon-bluegreen;
            }
            .c-layer-search__inner {
                background: rgba($color-amazon-bluegreen, 0.04);
                border: 1px solid rgba($color-amazon-bluegreen, 0.1);
                color: $color-amazon-bluegreen;
            }
        }
        .c-layer-search__autocomplete-list {
            li {
                a {
                    color: $color-amazon-bluegreen;
                }
            }
        }
    }

    // 검색레이어 개선
    .c-headline--low {
        .c-headline__title {
            height: 40px;
            font-size: 15px;
            line-height: 24px;
            span {
                margin-left: 8px;
                font-size: 13px;
                color: $color-gray-08;
                font-weight: normal;
            }
        }
    }
    // 최근검색어
    &__recent {
        position: relative;
        margin-top: 15px;
        .w-scroll-css {
            max-height: 80px;
        }
        .c-chip-input {
            padding: 0 16px;
            white-space: nowrap;
            &__row {
                display: flex;
            }
            .c-chip {
                flex-shrink: 0;
                line-height: 30px;
                a {
                    @include text-ellipsis;
                    max-width: 254px;
                }
                .delete {
                    &::before {
                        @include sprite-retina($sp_eui_delete);
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto auto auto 4px;
                        background-color: transparent;
                        transform: none;
                    }
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .c-dialog {
            &--menu {
                position: absolute;
                top: 38px;
                left: auto;
                right: 16px;
                width: 118px;
                border-radius: 4px;
                transform: none;
                ul {
                    li {
                        min-height: initial;
                        padding: 0;
                        line-height: initial;
                        border-top-color: $color-gray-12;
                    }
                }
                button {
                    display: block;
                    width: 100%;
                    height: 43px;
                    padding: 0 16px;
                    font-size: 15px;
                    color: $color-gray-03;
                    line-height: 43px;
                    background: none;
                    border: 0;
                    text-align: left;
                    &.disabled,
                    &:disabled {
                        color: $color-gray-07;
                    }
                }
            }
            &__items {
                ul {
                    li {
                        padding: 0;
                    }
                }
            }
        }
        .c-nodata {
            padding: 4px 16px 16px;

            &__text {
                color: $color-gray-07;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    &__menu {
        position: absolute;
        top: 8px;
        right: 16px;
        width: 24px;
        height: 24px;
        border: 0;
        background-color: transparent;
        font-size: 0;
        color: transparent;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 3px;
            height: 3px;
            margin: -8px 0 0 -2px;
            border-radius: 50%;
            box-shadow: 0 0 0 $color-gray-01, 0 6px 0 $color-gray-01, 0 12px 0 $color-gray-01;
            background-color: $color-gray-01;
            content: "";
        }
    }
    // 최근 본 상품
    &__product {
        position: relative;
        padding: 8px 0;
        .w-scroll-css {
            .s-list {
                padding: 0 11px 4px;
                li {
                    padding: 4px 5px;
                }
            }
        }
        .c-card-item {
            @include rounding-box;
            width: 100px;
            height: 182px;
            &__thumb {
                .c-lazyload {
                    border-radius: 0;
                }
            }
            &__info {
                padding: 10px 8px;
                .c-card-item {
                    &__price-info {
                        margin-top: 3px;
                        padding: 0;
                        .c-card-item__price {
                            display: flex;
                            font-size: 11px;
                            color: $color-gray-02;
                            strong {
                                @include text-ellipsis;
                                font-size: 15px;
                                letter-spacing: -0.01em;
                                line-height: inherit;
                            }
                        }
                    }
                    &__name {
                        display: block;
                        height: auto;
                        max-height: 34px;
                        margin: 0;
                        font-size: 13px;
                        line-height: 17px;
                    }
                    &__rate {
                        font-size: 14px;
                        strong {
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 18px;
                        }
                    }
                    &__price-del {
                        font-size: 12px;
                        color: $color-gray-08;
                        line-height: 18px;
                        del {
                            font-size: 14px;
                        }
                    }
                    &__fluid {
                        dd {
                            margin-top: 3px;
                            &:first-of-type {
                                margin-top: 0;
                            }
                        }
                    }
                    &__counsel {
                        margin-top: 8px;
                        padding: 0;
                        font-size: 12px;
                        em {
                            font-size: 14px;
                            margin-bottom: 3px;
                        }
                    }
                }
            }
            .c-nation {
                margin-bottom: 0;
                & + .c-card-item__name {
                    max-height: 17px;
                }
                .amazon {
                    @include logo-svg($logo-amazon, 47px, 14px);
                    margin: 1px 0 2px;
                    background-position: 0 0;
                }
            }
        }
    }
    &__more {
        position: absolute;
        top: 8px;
        right: 0;
        padding: 12px 16px;
        font-size: 13px;
        color: $color-gray-03;
    }
    // 실시간 쇼핑 검색어
    &__searchword {
        display: block;
        position: relative;
        letter-spacing: $body-spacing;
        .w-swiper {
            padding-right: 11px;
            padding-bottom: 28px;
        }
        .c-layer-search-swiper {
            margin-left: 11px;
            .swiper-slide {
                display: block;
                width: calc(100% - 32px);
                outline: none;

                &:focus:not(:focus-visible) {
                    outline: none;
                }
                &-prev {
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20%;
                        height: 100%;
                        background: transparent;
                        top: 0;
                        right: 0;
                        z-index: 2;
                    }
                }
                &-next {
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 20%;
                        height: 100%;
                        background: transparent;
                        top: 0;
                        left: 0;
                        z-index: 2;
                    }
                }
            }
            & + .c-layer-search__pagination.swiper-pagination-bullets {
                position: absolute;
                top: -30px;
                width: 100%;
            }
        }
        .b-search-list {
            @include rounding-box;
            overflow: hidden;
            margin: 4px 5px;
            background-color: $color-gray-14;
            border-radius: 4px;
            .b-search-list-box {
                display: block;
                padding: 0 12px;
                ul {
                    li {
                        position: relative;
                        border-bottom: 1px solid $color-gray-12;
                        &:last-child {
                            border-bottom: 0;
                        }
                        a {
                            display: flex;
                            overflow: hidden;
                            padding: 0 30px 0 0;
                            height: 48px;
                            color: $color-gray-02;
                            font-size: 15px;
                            line-height: 50px;
                            strong {
                                margin-left: 4px;
                                color: $color-11st-red;
                            }
                            em {
                                overflow: hidden;
                                position: absolute;
                                top: 50%;
                                right: 4px;
                                transform: translateY(-50%);
                                font-size: 0;
                                &.up {
                                    @include ranking-arrow(up);
                                    margin-right: 6px;
                                }
                                &.down {
                                    @include ranking-arrow(down);
                                    margin-right: 6px;
                                }
                                &.same {
                                    @include ranking-arrow(same);
                                    width: 6px;
                                    margin-right: 7px;
                                }
                                &.new {
                                    @include ranking-arrow(new);
                                }
                            }
                        }
                        .del-item {
                            overflow: hidden;
                            width: 42px;
                            height: 48px;
                            border: none;
                            color: transparent;
                            background-color: transparent;
                            position: absolute;
                            right: -16px;
                            top: 0;
                            outline: none;
                            &:after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 36px;
                                height: 36px;
                                margin: -18px 0 0 -18px;
                                background-image: linear-gradient(#ccc, #ccc), linear-gradient(#ccc, #ccc);
                                background-repeat: no-repeat;
                                background-position: 50% 50%;
                                background-size: 1px 24px, 24px 1px;
                                border-radius: 50%;
                                transform: rotate(45deg) scale(0.5);
                            }
                        }
                        .keyword {
                            display: inline-block;
                            overflow: hidden;
                            margin-left: 10px;
                            font-size: 15px;
                            color: $color-gray-02;
                            line-height: 50px;
                            vertical-align: top;
                        }
                        .description {
                            @include text-ellipsis;
                            display: inline-block;
                            overflow: hidden;
                            margin-left: 6px;
                            font-size: 12px;
                            color: $color-gray-06;
                            line-height: 50px;
                            vertical-align: top;
                            flex: 1;
                        }
                    }
                }
                &__none {
                    padding: 135px 0;
                    color: $color-gray-06;
                    font-size: 13px;
                    line-height: 18px;
                    text-align: center;
                }
            }
            // [TODO][김성재] AS-IS 검색레이어 사용중인 잔여 페이지 최종 확인 후 삭제 예정 + 아파트관리비, 비즈11번가, 장애인고용공단 페이지에서만 사용중.... (S)
            .b-search-bottom {
                overflow: hidden;
                border-top: 1px solid $color-gray-12;
                height: 40px;
                line-height: 40px;
                text-align: right;
                background-color: $color-gray-14;
                button {
                    height: 40px;
                    padding: 0 16px;
                    background-color: transparent;
                    border: 0;
                    color: $color-gray-04;
                    font-size: 12px;
                }
                .link-more {
                    position: relative;
                    font-size: 12px;
                    margin-right: 14px;
                    padding-right: 16px;
                    color: $color-gray-06;
                    &:after {
                        content: "";
                        display: block;
                        border: 1px solid #ccc;
                        border-width: 0 1px 1px 0;
                        position: absolute;
                        top: 3px;
                        right: 4px;
                        height: 7px;
                        width: 7px;
                        transform: rotate(314deg);
                    }
                }
                span {
                    position: relative;
                    font-size: 12px;
                    height: 42px;
                    padding-right: 16px;
                    color: $color-gray-07;
                }
            }
            // [TODO][김성재] AS-IS 검색레이어 사용중인 잔여 페이지 최종 확인 후 삭제 예정 + 아파트관리비, 비즈11번가, 장애인고용공단 페이지에서만 사용중.... (E)
            &__amazon {
                .b-search-list-box {
                    ul {
                        li {
                            a {
                                padding: 0;
                                strong {
                                    color: $color-amazon-bluegreen;
                                }
                            }
                        }
                    }
                }
            }
            // [TODO][김성재] AS-IS 검색레이어 사용중인 잔여 페이지 최종 확인 후 삭제 예정 + 아파트관리비, 비즈11번가, 장애인고용공단 페이지에서만 사용중.... (S)
            &__viewed {
                .b-search-list-box {
                    ul {
                        li {
                            .c-card {
                                box-sizing: border-box;
                                min-height: initial;
                                height: 92px;
                                padding: 16px 0;
                                margin-right: 0;
                                line-height: initial;
                                font-size: $font-size-body2;
                                &__thumb {
                                    display: block;
                                    position: absolute;
                                    width: 60px;
                                    height: 60px;

                                    &:after {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        border: 1px solid rgba(0, 0, 0, 0.05);
                                        content: "";
                                    }
                                    img {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        width: 100%;
                                        height: 100%;
                                        max-width: initial;
                                        max-height: initial;
                                        object-fit: contain;
                                    }
                                }
                                &__info {
                                    display: block;
                                    position: relative;
                                    background-color: #fff;
                                    padding: 0 0 6px 6px;
                                    margin-left: 70px;
                                }
                                &__title {
                                    @include text-ellipsis-multiple(2);
                                    margin-top: 0;
                                    max-height: 34px;
                                    font-size: 14px;
                                    color: $color-gray-03;
                                    line-height: 17px;
                                }
                                &__price {
                                    padding-top: 3px;
                                    font-size: $font-size-body1;
                                    strong {
                                        font-size: $font-size-subtitle1;
                                    }
                                    &.stat-bold {
                                        font-size: $font-size-body2;
                                        color: $color-11st-red;
                                    }
                                    &.stat-bold2 {
                                        font-size: $font-size-body2;
                                        color: $color-11st-blue;
                                    }
                                }
                                &__list--amazon {
                                    .c-nation {
                                        margin-bottom: 4px;

                                        .amazon {
                                            @include logo-svg($logo-amazon, 47px, 14px);
                                            margin-top: 5px;
                                            margin-bottom: -5px;
                                        }
                                    }
                                    .c-card__title {
                                        max-height: 17px;
                                        -webkit-line-clamp: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // [TODO][김성재] AS-IS 검색레이어 사용중인 잔여 페이지 최종 확인 후 삭제 예정 + 아파트관리비, 비즈11번가, 장애인고용공단 페이지에서만 사용중.... (E)
        }
        .c-pagination {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0 16px;
            text-align: right;
            &__indicator {
                span {
                    &:not(.on) {
                        opacity: 0.2;
                    }
                    & + span {
                        margin-left: 2px;
                    }
                }
            }
        }
        .c-nodata {
            display: flex;
            height: 527px;
            padding-bottom: 150px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-sizing: border-box;
            &__title {
                font-size: 15px;
                color: $color-gray-02;
                &:before {
                    @include get-sprite-common("icon_notice", 48);
                    display: block;
                    margin: 0 auto 25px;
                    width: 48px;
                    height: 48px;
                    content: "";
                }
            }
            &__text {
                margin-top: 7px;
                font-size: 13px;
                color: $color-gray-07;
            }
        }
    }

    // FE 컴포넌트 분리로 인한 애니메이션 깜빡임 현상 대응 코드.
    // 상용 선배포를 위해 { .mw-search-layer } 임시 클래스 활용 (추후 상용오픈 후 정리 예정)
    &.mw-search-layer.fadeOut {
        opacity: 0;
    }
    &.mw-search-layer.slideOutBottom {
        transform: translate(-50%, 0);
    }

    // 임시 레이어 버그 대응
    // 통스 QA완료 이후에 풀어 적용 예정
    &.mw-search-layer {
        top: 100%;
        height: 94%;
        .c-layer-search__contents {
            height: 100%;
            .c-layer-search__yscroll {
                max-height: calc((100% - 16px) - 66px);
            }
        }
    }

    &__banner {
        display: flex;
        align-items: center;
        height: 60px;
        padding: 10px 16px;
        background-color: $color-gray-14;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    &__banner-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: $color-gray-13;
        flex-shrink: 0;

        img {
            display: inline-block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            vertical-align: top;
            object-fit: cover;
        }
    }

    &__banner-text {
        flex: 1 1;
        margin-right: 10px;
        font-size: 13px;
        color: $color-gray-05;
        @include text-ellipsis;
    }

    &__banner-title {
        display: block;
        overflow: hidden;
        margin-bottom: 2px;
        font-size: 15px;
        font-weight: 800;
        color: $color-gray-02;
        text-overflow: ellipsis;
    }

    &__shortcut {
        margin-left: auto;
        font-size: 13px;
        color: $color-11st-blue;
        flex-shrink: 0;

        &::after {
            display: inline-block;
            margin: 1px 0 0 4px;
            vertical-align: top;
            content: "";
            @include sprite-retina($sp_gnb2_arrow-right-blue);
        }
    }
}

// 검색레이어 동작별 대응 (fadein / BottomToTop)
// .c-layer-search {
//     top: 100%;
//     height: 94%;
//     &.fadeOut {
//         opacity: 0;
//     }
//     &.slideOutBottom {
//         transform: translate(-50%, 0);
//     }
//     .c-layer-search__contents {
//         height: 100%;
//         .c-layer-search__yscroll {
//             max-height: calc((100% - 16px) - 66px);
//         }
//     }
// }
