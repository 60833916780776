[data-ui-type="Store_Product_List"] {
    padding: 16px 8px;

    [data-ui-type="Store_Product_Sorting"] + & {
        padding-top: 0;
    }
    .l-grid__col {
        position: relative;
        padding: 0 8px 12px;

        &.l-grid__col--12:last-child {
            // 리스트뷰
            .c-card__list {
                min-height: initial;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
    }

    // 리스트 공통 적용
    .c-card__link {
        display: block;
        position: relative;
        z-index: 1;
    }
    .c-card__info {
        .c-card__name {
            display: block;
            height: auto;
            max-height: 36px;
            margin: 0 40px 7px 0;
            line-height: 18px;

            &.c-card__name-full {
                margin-right: 0;
            }
        }
        .c-card__rate {
            strong {
                font-size: 18px;
                font-weight: normal;
            }
            .c-card__special {
                font-size: 13px;
                font-weight: bold;
            }
        }
        .c-card__price {
            font-size: 13px;
            vertical-align: initial;

            strong {
                font-size: 18px;
            }
        }
        .c-card__benefit {
            height: auto;
            padding-top: 7px;
            font-size: 13px;
            letter-spacing: $body-spacing;

            .benefit {
                padding-bottom: 2px;
            }
        }
        .c-flag-box {
            margin: 4px 0 2px;
            padding: 0 30px 0 0;
        }
        .c-card__name {
            em {
                margin-right: 4px;
                color: $color-11st-blue;
            }
        }
        .c-flag__item--ooah,.c-flag__item--freshtable {
            margin: 3px 4px 7px 0;
        }
    }
    .c-card__counsel {
        padding: 4px 0 0;
        font-size: 13px;
        color: #999;

        em {
            padding-bottom: 2px;
        }
    }
    .c-card__button {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding-top: 100%;
        text-align: right;

        .c-like__btn {
            position: relative;
            z-index: 10;
            margin: 10px -4px 0 0;
        }
    }
    .c-cap {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 24px;
        height: 28px;
        background: none;
        text-align: center;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-style: solid;
            border-width: 23px 12px 5px;
            opacity: 0.8;
            content: "";
        }
        &__rank {
            display: inline-block;
            position: relative;
            top: auto;
            left: auto;
            font-size: 13px;
            font-weight: bold;
            color: #fff;
            line-height: 28px;
            vertical-align: top;
        }
    }
    .c-prd-info__meta {
        margin-top: 4px;

        .c-prd-review__ratesmall {
            margin: 3px 3px 0 0;
        }
    }
    // 갤러리뷰
    .c-card__gallery {
        .c-card__thumb {
            width: initial;
            height: initial;
        }
        .c-card__info {
            padding: 14px 0 6px;
            min-height: initial !important; //서비스 반영 후 important 삭제예정

            .c-card__name {
                height: auto;
            }
            .c-card__rate--full {
                display: block;
                float: none;
                margin-bottom: 2px;
            }
            .c-card__benefit {
                height: auto;

                strong {
                    color: #999;
                }
                .benefit {
                    display: block;
                    padding-bottom: 3px;
                }
            }
            .c-card__price {
                height: auto;
                vertical-align: initial;
            }
        }
    }
    //리스트뷰
    .c-card__list {
        padding: 4px 0 4px;
        border-bottom: 1px solid #eee;

        .c-card__link {
            display: block;
            min-height: 124px;
        }
        .c-card__thumb {
            width: 124px;
            height: 124px;
        }
        .c-card__info {
            margin-left: 124px;
            padding-left: 14px;

            .c-card__name {
                height: auto;
                margin-right: 30px;

                &.c-card__name-full {
                    display: block;
                    float: none;
                    margin-right: 0;
                }
            }
            .c-card__rate--full {
                display: block;
                float: none;
            }
            .c-card__benefit {
                overflow: hidden;
                padding-top: 7px;

                .benefit {
                    display: inline-block;
                    position: relative;
                    margin-right: 13px;

                    &:before {
                        position: absolute;
                        top: 7px;
                        left: -8px;
                        width: 2px;
                        height: 2px;
                        background: #ddd;
                        vertical-align: middle;
                        content: "";
                    }
                }
            }
        }
        .c-card__button {
            position: static;
            top: 0;
            right: 0;
            left: 0;
            padding-top: 0;
            text-align: right;

            .c-like__btn {
                position: absolute;
                top: 0;
                right: -4px;
                margin: 0;
            }
        }
    }
    //확대뷰
    .c-card__big {
        .c-card__thumb {
            @media screen and (min-width: 360px) {
                & {
                    @include size(100%);
                }
            }
        }
        .c-card__info {
            padding: 16px 0 8px;

            .c-card__rate {
                strong {
                    font-size: 22px;
                }
                &--full {
                    font-size: 16px;
                }
                .c-card__special {
                    font-size: 16px;
                    font-weight: normal;
                }
            }
            .c-card__price {
                strong {
                    font-size: 22px;
                }
            }
            .c-card__benefit {
                overflow: hidden;

                .benefit {
                    display: inline-block;
                    position: relative;
                    margin: 0 14px 0 0;
                    padding: 0;
                    border: 0;

                    &:before {
                        position: absolute;
                        top: 7px;
                        left: -8px;
                        width: 2px;
                        height: 2px;
                        background: #ddd;
                        vertical-align: middle;
                        content: "";
                    }
                }
            }
        }
    }
    .c-card__vertical {
        margin: -8px 0;
        padding-top: 0;

        .c-card {
            &__list {
                padding-top: 0;
            }
            &__link {
                display: table;
                table-layout: fixed;
            }
            &__thumb,
            &__info {
                display: table-cell;
                vertical-align: middle;
            }
            &__thumb {
                position: static;
            }
            &__info {
                padding-bottom: 0;
            }
        }
    }
    .c-card--small {
        padding-bottom: 18px;
        .c-card {
            &__link {
                min-height: 72px;
            }
            &__thumb {
                @include size(72px);
            }
            &__info {
                margin-left: 72px;
                padding-left: 20px;
                .c-card__name {
                    line-height: 20px;
                    margin-bottom: 12px;
                }
                .c-card__price {
                    strong {
                        font-size: $font-size-subtitle1;
                    }
                }
            }
        }
    }
    .c-card--box {
        padding: 18px 30px 18px 16px;
        margin-bottom: 5px;
        &:nth-last-of-type(1) {
            margin-bottom: -12px;
        }

        label {
            display: inline-block;
            color: $color-gray-03;
            h4 {
                color: $color-gray-03;
            }
        }

        .c-toggle--open {
            display: block;
        }

        .c-toggle {
            @include size(40px);
            position: absolute;
            top: 10px;
            right: 10px;
            border: 0;
            background: none;
            color: transparent;
            outline: none;
            &::after {
                @include ico-arrow-down(8px, #999, 1px);
                content: "";
                position: absolute;
                top: 50%;
                right: 16px;
                margin-top: -8px;
            }
            &[aria-expanded="true"] {
                &::after {
                    @include ico-arrow-up(8px, #999, 1px);
                    margin-top: -4px;
                }
            }
        }
        .c-link-more {
            display: inline-block;
            padding: 6px 12px 0 30px;
            font-size: $font-size-body1;
            color: $color-gray-02;
            position: relative;
            &::after {
                @include ico-arrow-right(8px, #111, 1px);
                content: "";
                position: absolute;
                right: 2px;
                top: 10px;
            }
        }
    }
}
.l-grid__row-flow {
    overflow: hidden;
    margin: 0 8px;

    .c-card__gallery {
        .c-card__info {
            padding-bottom: 12px;
        }
    }
}
