$sprite-meta-mart: (
    "name": 'mart', // sprite 이름
    "width": 135, // sprite 판 너비
    "height": 133, // sprite 판 높이
);

$sprite-map-mart: (
  'ic_tabbar_home': (
      "width": 37,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": 0 - (45 - 37)/2,
  ),
  'ic_tabbar_home_on': (
      "width": 36,
      "height": 36,
      "bg-top": -44 - (44 - 36)/2,
      "bg-left": -90 - (44 - 36)/2,
  ),
  'ic_tabbar_menu': (
      "width": 37,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -45 - (45 - 37)/2,
  ),
  'ic_tabbar_menu_on': (
      "width": 37,
      "height": 36,
      "bg-top": -44 - (44 - 36)/2,
      "bg-left": 0 - (45 - 37)/2,
  ),
  'ic_tabbar_personal': (
      "width": 37,
      "height": 36,
      "bg-top": -44 - (44 - 36)/2,
      "bg-left": -45 - (45 - 37)/2,
  ),
  'ic_tabbar_personal_on': (
      "width": 37,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -90 - (45 - 37)/2,
  ),
  'ic_tabbar_recent': (
      "width": 37,
      "height": 37,
      "bg-top": -88 - (45 - 37)/2,
      "bg-left": 0 - (45 - 37)/2,
  ),
  'ic_tabbar_recent_on': (
      "width": 37,
      "height": 37,
      "bg-top": -88 - (45 - 37)/2,
      "bg-left": -45 - (45 - 37)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-mart($name, $width: null, $type: null) {
    $sp-w: map-get($sprite-meta-mart, "width");
    $sp-h: map-get($sprite-meta-mart, "height");
    $img: map-get($sprite-map-mart, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;

    @if $type == null {
      background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      background-size: #{$bgw}px #{$bgh}px;
    } @else if $type == "mask" {
      mask-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      mask-size: #{$bgw}px #{$bgh}px;
    }
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-mart($name: null, $tmp-width: null, $type: null) {
    display: inline-block;
    
    @if $type == null {
      vertical-align: top;
      background: url("/MW/img/svg/sprites/sp_mart_2025313_164423.svg") no-repeat;
    } @else if $type == "mask" {
      mask-image: url("/MW/img/svg/sprites/sp_mart_2025313_164423.svg");
    }
    @if $name != null {
      @include get-sprite-position-mart($name, $width: $tmp-width, $type: $type);
    }
}