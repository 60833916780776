[data-ui-type="Store_TabMenu"] {
    letter-spacing: $body-spacing;

    .c-nav {
        border-bottom: none;
        &__wrap {
            .c-nav__list {
                padding: 0;
            }
            .c-nav__item {
                width: 33.333333%;
                a {
                    padding: 2px 0;
                    color: $color-gray-02;
                    line-height: 48px;
                }
                &.on {
                    &:first-child,
                    &:last-child {
                        a {
                            padding: 2px 0;
                        }
                    }
                    a {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
