.c-dialog.c-dialog--luxury {
    width: 288px;
    background-color: transparent;

    @media screen and (min-width: 360px) {
        width: 327px;
    }

    .c-dialog {
        &__text {
            margin: 0;
            padding: 34px 16px;
            color: $color-gray-03;
            font-size: 15px;
            line-height: 20px;
        }
        &__buttons {
            background-color: #fff;

            .c-dialog__button {
                color: #333;
                font-weight: 500;
            }
            .c-dialog__button-point {
                color: $color-luxury;
            }
        }
        &__image {
            display: block;
            width: 100%;
        }
    }
}
