.c-gotop--luxury {
    bottom: 69px;
    display: block;
    width: 48px;
    height: 48px;
    border: 0;
    color: transparent;
    border-radius: 24px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(16, 0, 0, 0.14);
    background: #fff;
    opacity: 0.98;

    &:before {
        @include sprite-retina($sp_store_new_ic_gotop);

        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.c-tabbar--luxury {
    padding-bottom: 0;
}

// 명품 셀러 전용 c-gotop으로 인해 푸터 여백 조정
.c-footer__info {
    padding-bottom: 120px;
}
