.store_coupon_wrap {
    position: relative;
    h1 {
        height: 52px;
        background-color: #fff;
        border-bottom: 1px solid #d1d1d6;
        color: #111;
        font-size: 18px;
        text-align: center;
        line-height: 52px;
        font-weight: normal;
    }
    .btn_coupon_close {
        position: absolute;
        right: 0;
        top: 0;
        width: 52px;
        height: 52px;
        border: 0;
        background: none;
        color: transparent;
        text-indent: -9999px;
        &:before,
        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: '';
            width: 1px;
            height: 19px;
            margin-top: -10px;
            background-color: #000;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
.coupon-store {
    letter-spacing: $body-spacing;
    padding-top: 8px;
    .coupon_wrapper {
        position: relative;
        min-height: 97px;
        height: auto;
        margin: 0;
        padding: 15px 77px 15px 138px;
        box-sizing: border-box;
        background-color: #fff;
        border-bottom: 1px solid #f4f4f4;
    }
    .c-coupon {
        display: inline-block;
        position: absolute;
        left: 15px;
        top: 16px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            z-index: 11;
        }
        &_contents {
            position: relative;
            width: 110px;
            height: 55px;
            padding: 9px 15px 0 16px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 4px;
            overflow: hidden;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                width: 5px;
                height: 55px;
                background-color: #f00;
                z-index: 10;
            }
            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                border-width: 29px 0 26px 24px;
                border-color: #f00 #fff;
                border-style: solid;
                z-index: 10;
            }
        }
        &_text {
            color: $color-11st-red;
            font-size: 11px;
            font-weight: bold;
        }
        &_discountrate {
            position: relative;
            z-index: 15;
            color: #111;
            font-size: 19px;
            strong {
                font-size: 16px;
            }
        }
    }

    .coupon_info {
        padding-top: 2px;
        p {
            margin-top: 5px;
            font-size: 12px;
            color: #666;
            &:nth-last-child(2) {
                margin-top: 2px;
            }
            &.max {
                margin-top: 2px;
                color: #111;
                font-size: 15px;
                font-weight: bold;
                max-height: 38px;
                overflow: hidden;
            }
            span {
                letter-spacing: 0;
            }
            .c-coupon__addition {
                position: relative;
                display: inline-block;
                margin: 0;
                padding-right: 24px;
                height: 18px;
                line-height: 18px;
                font-size: 13px;
                color: #999;
                &:after {
                    @include sprite-retina($sp_coupon_store_more);
                    content: '';
                    position: absolute;
                    right: 0;
                    background-position: -27px -3px;
                }
                &.selected {
                    padding-right: 0;
                    padding-left: 20px;
                    color: $color-11st-red;
                    &:before {
                        @include sprite-retina($sp_coupon_store_check);
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 2px;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
            a {
                display: inline-block;
                position: relative;
                padding-right: 22px;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    margin-left: 5px;
                    border-top: 1px solid #9d9d9d;
                    border-left: 1px solid #9d9d9d;
                    transform: rotate(-135deg);
                    vertical-align: 2px;
                    transition: transform .3s;
                }
                &.on {
                    &:after {
                        transform: rotate(45deg);
                        vertical-align: -1px;
                    }
                }
                &.btn_add_product {
                    position: relative;
                    &:before, &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 11px;
                        top: 50%;
                        width: 1px;
                        height: 10px;
                        margin: -6px 0 0;
                        background-color: #9d9d9d;
                        background-image: none;
                    }
                    &:before {
                        transform: rotate(0);
                    }
                    &:after {
                        transform: rotate(-90deg);
                    }
                }
            }
            &:last-child {
                margin-top: 5px;
            }
        }
    }

    .btn_coupon_down {
        position: absolute;
        right: 0;
        top: 0;
        width: 72px;
        min-height: 96px;
        height: 100%;
        background: none;
        border: 0;
        color: #999;
        font-size: 13px;
        border-left: 1px dashed #eee;
        box-sizing: border-box;
        &[disabled="disabled"] {
            -webkit-tap-highlight-color: transparent;
        }
        span {
            @include sprite-retina($sp_coupon_store_get);

            display: block;
            text-indent: -99999px;
            margin: 0 auto 8px;
        }
        em {
            color: $color-11st-red;
            font-style: normal;
        }
    }

    .category_view {
        display: none;
        position: relative;
        background-color: #fafafa;
        &:after {
            content: '';
            display: block;
            border-bottom: 1px solid #eee;
        }
        .btn_coupon_close {
            width: 48px;
            height: 48px;
            &:before,
            &:after {
                height: 14px;
                margin-top: -7px;
            }
        }
        .limit_category {
            padding: 12px 16px;
            li {
                color: #666;
                font-size: 13px;
                line-height: 19px;
            }
        }
    }
}
