[data-ui-type="Store_Contents_Home_ReviewScroll"] {
    .c-card__gallery {
        width: 218px;

        .c-card__thumb {
            width: 218px;
            height: 218px;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .c-prd-review {
        margin-top: 12px;
    }
    .s-list {
        padding: 2px 10px 14px;

        li {
            padding: 6px;
        }
    }
}