[data-ui-type="Store_Product_Empty"] {
    padding: 112px 0;
    font-size: 14px;
    color: $color-gray-04;
    text-align: center;

    strong {
        display: block;
        margin-bottom: 16px;
        font-size: 15px;
        color: $color-gray-02;
    }
    span {
        line-height: 1.7;
    }
}
