[data-ui-type="Store_Contents_Home_ReviewBasic"],
[data-ui-type="Store_Contents_Home_ReviewScroll"],
[data-ui-type="Store_Product_List"],
[data-ui-type="Store_Contents_Review"],
[data-ui-type="Store_Product_Recommend"] {
    .c-prd-review {
        &__rate {
            @include sprite-retina($sp_store_review_star_bg);
            display: inline-block;
            margin-top: 3px;
            vertical-align: top;
        }
        &__ratesmall {
            @include sprite-retina($sp_store_review_small_star_bg);
            display: inline-block;
            vertical-align: top;

            &-value {
                display: inline-block;
                vertical-align: top;
            }
        }
        &__rate-num {
            margin-left: 4px;
            font-size: 14px;
            font-weight: normal;
            color: #111;
            vertical-align: top;
        }
        &__name {
            display: block;
            height: 18px;
            margin-top: 10px;
            font-weight: normal;
        }
        &__comment {
            display: block;
            height: 36px;
            margin-top: 4px;
            font-size: 14px;
            color: #666;
            line-height: 1.4;
        }
    }
}
